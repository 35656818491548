// ERROR SCREEN
@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/breakpoints' as *;


// .control{
//     display:flex;
// }

.control-select{
    font-size:$font-size-body;
    background-color:$col-bg-dark;
    border:0;
    border-radius:2rem;
    padding:1rem 1.2rem;
    border-right:1.2rem solid transparent;
    height:4rem;
    cursor:pointer;
    color:black;
}
.control-select.disabled{
    opacity:0.5;
    cursor:default;
}

.control-select:hover{
    background-color:$col-bg-darker;
}
.control-select.disabled:hover{
    background-color:$col-bg-dark;
}


   