// MODAL SEARCH

@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/breakpoints' as *;
@use '../../assets/styles/modal' as *;

$tab-background-hue: 233deg;
$tab-text-color: $col-header-near-solid;


$search-field-height: 4rem;
$search-icon-width: 3rem;
$input-field-padding: 3.3rem;
$input-field-width: calc(100% - 3.3rem);

.modal-paper-search{
    width:100%;
    transition: all 1s;
}


.modal.search{
    width:unset;
    border-radius:0 0 0.5rem 0.5rem;
    margin:0;
    // background-color:transparent;
    background-color:$col-light-translucent;
    max-width:unset;
    top:0rem;
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1)  0.5s;
    opacity:1;
}

.modal.search.unrevealed{
    top:-10rem;
    opacity: 0;
}

.modal.search .modal-header{
    z-index:1;
    height:5rem;
    background-color:transparent;
}

.search-body{
    // padding:2rem;
    background-color:transparent;
    transition: all 1s;
    z-index:0;
    margin-top: -3rem;
}

.search .close-but .but-icon{
    color:$col-header;
    width:2rem;
    height:2rem;
    // opacity:0.3;
    transition:color 0.15s;
}
.search .close-but:hover .but-icon{
    // color:$col-dark;
    color:$col-header;
}

// --------------------------------------------------------------
// SEARCH BOX
.search-box-container{
    // width:100%;
    display:flex;
    flex-direction:column;
    align-content:center;
    margin: 0 2rem;
}
.search-box{
    width:100%;
    max-width:$max-readable-width;
    display:flex;
    flex-direction:column;
    
    margin-top:2rem;
    
    align-self:center;
}

.search-form-container{
    margin:0;
    padding:0;
    width:100%;
    display:flex;
    flex-direction: row;
    justify-content:space-between;
    
    position:relative;
}
.search-form{
    margin:0;
    padding:0;
    width:100%;
    
}



.search-field{
    border:0;
    outline:0;
    height:$search-field-height ;
    width:$input-field-width;
    font-size:$font-size-body-xl;
    background-color:transparent;
    // background-color:$col-dark-vapour;
    padding:0;
    padding-left:$input-field-padding;
    border-bottom:1px solid $col-dark-wraith;
    transition: border 1s;

    // Background color
    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px $col-light inset;
    }
    
    // Font styles of input field when user hoers over autofill option
    &:-webkit-autofill::first-line {
    font-family: $font-ui;
    font-size: $font-size-body-xl;
    // font-weight: bold;
    // color: green;
    }

}

.search-field:focus{
    border-bottom:1px solid $col-highlight;
}

.search-but{
    padding:0;
    background-color:transparent;
    // margin-right:0.5rem;
    display:flex;
    flex-direction:row;
    position:absolute;
    left:0;
    width:$search-icon-width;
    height:$search-field-height;
}
.search-but:hover{
    background-color:transparent;
}
.search-but .search-icon{
    width:$search-icon-width;
    height:$search-icon-width;
    color:$col-dark-wraith;
    transition:color 0.15s;
    display:flex;
    align-self:center;

}
.search-but .search-icon:hover{
    // color:$col-highlight;
}

.search-help{
    margin-top:1rem;
    margin-bottom:0.3rem;
}
.search-help p, .search-help form {
    margin:0;
    color:$col-dark-faint;
	font-family: $font-ui;
}


// --------------------------------------------------------------
// AUDIENCE FILTER GROUP

.modal.search .audiences input[type=radio] {
	margin-top: 0rem;
	vertical-align: middle;
}


.modal.search .audiences label {
	padding-inline: 0.2rem;

	@include bp(s){
		padding-inline:0.1rem;
	}
}
.modal.search .audiences.textual button {
	padding-left: 0.15em;
}




// ----------------------------------------------------------------
// AUDIENCE FILTER – SYSTEM DROPDOWN

.modal.search .audiences.systemDropdown  {
	color: $col-dark-faint;
	font-family: $font-ui;
	font-size: $font-size-body;
}
.modal.search .audiences.systemDropdown select {
	display: inline-block;
	font-size: $font-size-body;
	font-family: $font-ui;
	padding: .5em 2.4em .5em .7em;
	margin: 0;
	margin-left: 0.08em;
	border: 0px;
	border-radius: .8em;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	color: $tab-text-color;
	background-color: hsl($tab-background-hue, 14%, 91%);
	background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E');
	background-repeat: no-repeat, repeat;
	background-position: right .7em top 50%, 0 0;
	background-size: .65em auto, 100%;
}
.modal.search .audiences.systemDropdown select:hover {
	background-color: hsl($tab-background-hue, 14%, 89%);
}




// ----------------------------------------------------------------
// AUDIENCE FILTER – CUSTOM DROPDOWN

.modal.search .audiences.dropdown > div {
	padding-left: 0.15em;
	position:relative;
}

.modal.search .audiences.dropdown .select {
	display: inline-block;
}
.modal.search .audiences.dropdown .select > .name {
	background-color: hsla(0, 0%, 100%, 70%);
	padding-inline: 0.3em;
	padding-block: 0.2em;
}
.modal.search .audiences.dropdown .select.open > .name {
	background-color: hsla(0, 0%, 100%, 100%);
}
.modal.search .audiences.dropdown   ul {
	display: none;
	list-style: none;
	padding-left: 0;
	background-color: white;
	margin:0;
	margin-left:0;
	// width: 100%;
	position:absolute;
	top: calc(1em + 0.3rem + 0.2em);
}
.modal.search .audiences.dropdown li {
	margin:0;
	text-align:left;
}
.modal.search .audiences.dropdown li button {
	padding-block:0.5em;
	padding-bottom:0.3em;
	padding-right:0.3em;
	padding-left: 0.3em;
	min-width: 10ch;
	text-align:left;
}
.modal.search .audiences.dropdown li:has(button:hover) {
	background-color: hsla(0,0%,0%, 2%);
}
.modal.search .audiences.dropdown li.selected button {
	// background-color: hsla(0,0,0%, 5%);
	font-weight: bold;
}
.modal.search .audiences.dropdown .select.open  ul {
	display: inline-block;
	z-index: 100;
}
.modal.search .audiences.dropdown .select.open .scrim { 
	// pointer-events:none;
	cursor: pointer;
	position: fixed;
	display: block;
	z-index: 99;
	background-image: linear-gradient(hsla(45,90%,50%,0%), hsla(45,90%,50%,0%));
	top: 0px; left:0px;
	// background-color: hsla(0,0,0, 10%);
	width: 100vw;
	height: 100vh;
}

// --------------------------------------------------------------
// TABS



.modal.search ul.search-tabs {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	list-style-type: none;
	padding-inline-start: 0;
}
.modal.search ul.search-tabs button {
	color: $tab-text-color;
	border: 0px;
	border-radius: 1em;
	padding: 0.6rem 1.8rem;	
	white-space: nowrap;
	margin-right: 0.6rem;
}

.modal.search ul.search-tabs li {
	margin-block: 0.3rem;
}


/* TAB BUTTON: NORMAL */
.modal.search ul.search-tabs button {
	background-color: hsl($tab-background-hue, 14%, 91%);
}
.modal.search ul.search-tabs li button:hover {
	background-color: hsl($tab-background-hue, 14%, 89%);
}
.modal.search ul.search-tabs li button:active {
	background-color: hsl($tab-background-hue, 14%, 80%);
}

.modal.search ul.search-tabs li.selected button:active {
	color: #111c;
	background-color: hsl($tab-background-hue, 0%, 98.8%);
}
.modal.search ul.search-tabs li.selected button {
	color: #111;
	background-color: hsl($tab-background-hue, 0%, 100%);
}


/* TAB BUTTON: EMPTY */
.modal.search ul.search-tabs li.empty button {
	color: hsla(263, 34%, 32%, 0.561);
}
.modal.search ul.search-tabs li.empty.active button {
	color: hsla(263, 34%, 32%, 0.561);
}
.modal.search ul.search-tabs li.selected.empty button {
	color: #111;
}
.modal.search ul.search-tabs li.selected.empty.active button {
	color: #111c;
}
 

// --------------------------------------------------------------
// RESULTS
.search-results-container{

}

.search-results-items{
    padding-bottom: 2rem;
}

.searching{
    display:flex;
    flex-direction:row;
    justify-content:center;
    margin-top:2rem;
    margin-bottom:2rem;
}
.spinner-container-search{
    display:flex;
    background-color:transparent;
}
.spinner-container-search div svg{
    background-color:transparent !important;
}
.search-results-empty{
    padding-left:2rem;
    padding-right:2rem;
}
.search-results-empty p{
    color:$col-dark-faint;
    
}

.search-results-header{
    margin:1rem 2rem;
}


