$col-text:#111;
$col-text-on-yellow:#625403;
$col-text-faint:#11111188;
$col-text-ghost:#11111166;
$col-text-wraith:#11111133;
$col-header:#4b366e;
$col-header-dark:#362552;
$col-header-dark-faint:#21143655;
$col-header-near-solid:#4b366ef5;
$col-header-translucent:#4b366edd;
$col-header-faint:#4b366e88;
$col-header-vapour:#4b366e0d;
$col-header-vibrant:#4E3874;
$col-link:#708938;
$col-highlight-but:#5d742a;
$col-highlight:#5a702b;
$col-highlight-translucent:#708938cc;
$col-highlight-light:#708938aa;
$col-highlight-faint:#70893888;
$col-highlight-ghost:#70893844;
$col-highlight-wraith:#70893822;
$col-highlight-vapour:#70893811;
$col-highlight-none:#70893801;
$col-green:#60964A;
$col-green-but:#4c7d39;
$col-green-hover:#5b9145;
$col-green-ghost:#60964A44;
$col-green-vapour:#60964A11;
$col-green-imagined:#60964A08;
$col-dark: #494746;
$col-dark-deep: #222;
$col-dark-faint: #11111199;
$col-dark-spirit: #11111155;
$col-dark-ghost: #11111144; 
$col-dark-wraith: #11111122;
$col-dark-vapour: #11111111;
$col-dark-imagined: #11111105;
$col-light: #fff;
$col-light-translucent: #ffffffee;
$col-light-faint: #ffffffaa;
$col-light-wraith: #ffffff22;
$col-light-vapour: #ffffff11;
$col-light-off: #F5F4F3;
$col-green-light:#F6F7F1;
$col-but-type1-bg:#eff0e9;
$col-but-type1-bg-hover:#eaece2;
$col-blue:#346DA3;
$col-blue-hover:#30699f;
$col-pink:#B43D92;
$col-pink-hover:#B0398e;
$col-yellow:#E2CC48;
$col-yellow-but:#c2a60d;
$col-yellow-dark:#a88f07;
$col-yellow-hover:#c7b133;
$col-bg:#fafaf7;
$col-bg-dark:#ecece6;
$col-bg-darker:#deded5;
$col-bg-darker-translucent:#deded588;
$col-bg-dark-translucent:#ecece6f5;
$col-gold:#F6AE2C;

$col-difficulty-faint-1:#60B7AB22;
$col-difficulty-faint-2:#7FA75722;
$col-difficulty-faint-3:#C6B84622;
$col-difficulty-faint-4:#E0A12E22;
$col-difficulty-faint-5:#DF5E2622;
$col-difficulty-faint-6:#D5516622;
$col-difficulty-1:#60B7AB;
$col-difficulty-2:#7FA757;
$col-difficulty-3:#C6B846;
$col-difficulty-4:#E0A12E;
$col-difficulty-5:#DF5E26;
$col-difficulty-6:#D55166;


$col-error:#aa3333;
$col-warning:#cc8833;
