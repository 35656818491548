// Nav

@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/breakpoints' as *;



.home-promo{
    position:relative;
    display:flex;
    flex-direction:row;
    align-content:center;
    justify-content:center;
    width:100%;
    max-width:100%;
    overflow:hidden;
    padding-top:4rem;
    padding-bottom:4rem;
    
    max-width: 100%;
    background-color:$col-bg;
    // border:2px solid black;
    
    @include bp(s){
        flex-direction: column;

    }

    

    .block{

        display:flex;
        flex-direction:column;
        position:relative;
        height:20rem;
        max-width: 100%;
        @include bp(m){
            // height:30rem;
        }

        @include bp(s){
            align-self:center; 
            // height:30rem;
            
            .home-promo-text{
                align-self:center;
            }
        }

       

        .home-promo-bg{
            position:absolute;
            width:50rem;
            height:13rem;
            bottom:2rem;
            z-index:0;
            img{
                width:100%;
                height:100%;
            }
        }
        
        .home-promo-bg.bg1{
            left:-35rem;
            @include bp(m){
                // bottom:6rem;
            }

            @include bp(s){
                left:-35rem;
            }
            @include bp(xxs-down){
                left:-30rem;
            }

        
        }
        .home-promo-bg.bg2{
            right:-35rem;

            @include bp(m){
                // bottom:6rem;
            }

            @include bp(s){
                right:-35rem;
            }
            @include bp(xxs-down){
                right:-40rem;
            }

        
        }
        .home-promo-image{
            position:absolute;
            width:16.21rem;
            height:20rem;
            bottom:0rem;
            z-index:0;
            @include bp(xxs-down){
                width:9.7rem;
                height:12rem;
            }

            @include bp(s){
                width:11.35rem;
                height:14rem;
            }

            
            img{
                width:100%;
                height:100%;
            }
            
        }
        
        .home-promo-image.image1{
            left:-15rem;
            bottom:0.5rem;
            @include bp(m){
                left:-13rem;
                // bottom:5rem;
            }
            @include bp(s){
                left:-8rem;
            }
            @include bp(xxs-down){
                left:-8rem;
                bottom:0rem;
            }


        }
        .home-promo-image.image2{
            right:-16rem;
            bottom:5rem;
            
            @include bp(m){
                right:-14rem;
                // bottom:7rem;
            }
            @include bp(s){
                right:-11rem;
                bottom:8rem;
            }
            @include bp(xxs-down){
                right:-8rem;
                bottom:3rem;
            }
        }
        .home-promo-text{
            z-index:1;
            // width:5rem;
            
            // min-width:20rem;
            // min-width:unset;
            text-align: center;
            
            .title{
                color:$col-header;
                margin:0rem;
            }
            .subtitle{
                color:$col-header;
                margin-top:0.5rem;
                max-width:22rem;
                // border:1px solid green;    
            }
            .but{
                display: inline-block;
                // min-width: 55%;
            }
        
            .but:hover{
                text-decoration:none;
            }
        }
    }
    .block1{
        margin-right:10%;
        
        .but{
            background: rgb(133,160,66);
            background: linear-gradient(90deg, rgba(133,160,66,1) 0%, rgba(111,142,78,1) 100%);
            color:white;
        }
        .but:hover{
            background: linear-gradient(90deg, rgba(133,160,66,0.95) 0%, rgba(111,142,78,0.95) 100%);
        }

        @include bp(s){
            height:20rem;
            margin:0;
        }
    }

    .block2{
        
        .but{
            background: rgb(100,42,122);
            background: linear-gradient(90deg, rgba(100,42,122,1) 0%, rgba(86,66,121,1) 100%); 
            color:white;
        }
        .but:hover{
            background: linear-gradient(90deg, rgba(100,42,122,0.95) 0%, rgba(86,66,121,0.95) 100%); 
        }
        @include bp(s){
            height:18rem;
            margin-top:2rem;
        }
    }
}
