// MODAL MEDIA VIDEO
@use '../../assets/styles/colors' as *;
@use '../../assets/styles/breakpoints' as *;

.modal-media-video .media{
    
    // The min-height is declared here with a constant aspect ratio.
    // This will cause a slight visual jump if a video with a taller aspect ratio is loaded.
    // If a wider aspect ratio is loaded then a gap will appear under the video
    // min-height:calc((100%) * (9/16));


    background-color:$col-green-light;
}
.video-langs{
    height:4rem;
    display:flex;
    flex-direction:row;
    justify-content: flex-start;
    align-content:center;
    background-color:$col-green-light;
    padding:0 0.5rem;
}

.video-langs-icon{
    width:2rem;
    height:2rem;
    background-size:2rem 2rem;
    margin-right:0.5rem;
    background-image:url(../../assets/images/ui/menu-icon-lang-green-ga.png);
    background-repeat:no-repeat;
    display:flex;
    align-self:center;
    display:flex;

    @include bp(s){
        display:none;
        
    }
}


.video-langs ul{
    margin:0;
    display:flex;
    flex-direction:row;
    list-style-type: none;
    padding:0;
    align-self:center;
}

.video-langs li{
    margin-left:0.5rem;
    display:flex;
    flex-direction: column;
    justify-content:center;
}


.video-langs li button{
    border-radius:2rem;
    background-color: transparent;
    border:0;
    color:$col-highlight;
    cursor:pointer;
    padding:0.6rem 0.8rem;
}

.video-langs li button:hover{
    background-color: $col-highlight-wraith;
    // color:$col-light;
}

.video-langs li.active button{
    background-color: $col-highlight;
    color:$col-light;
    cursor:default;
}   

