// EXPANDER


.expander .hidden{
    transition:opacity 300ms ease-out;
    opacity:0;
    height:0;
    transform: scale(0)
}

.expander.expanded .hidden{
    opacity:0;
    height:unset;
    transform: none;
}

.expander.expanded .hidden.revealed{
    opacity:1;
    height:unset;
    transform: none;
}




.expander-buttons{
    display:flex;
    flex-direction: row;
    justify-content: center;
    width:100%;
}

.expander-buttons-left{
    justify-content: flex-start;
}

// ---------
// MORE BUTTON
.expander .more-but{
    display:flex;
}


// ---------
// LESS BUTTON
.expander .less-but{
    display:none;
}

.expander.expanded .less-but{
    display:flex;
}