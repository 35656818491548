// TopicSoundsItemPage

@use '../../assets/styles/colors' as *;
// @use '../../assets/styles/vars' as *;



#topic-sounds{

    .topic-sounds-item{
        .topic-subheader h2{
            margin-top:0.2em !important;
            margin-bottom:0;
        }
    
        .publication{
            margin-top:0.5rem;
            margin-bottom:0;
        }
    
        .author{
            margin:0;
            margin-top:0.7rem;
            
            .divider{
                margin:0 1em;
                opacity:0.2 ;
            }
        }
    
        
    
        .publisher{
            margin:0;
            margin-top:0.5rem;
            
        }
    
        .text{
            display:flex;
            flex-direction:column;
        }

        
    }
    .topic-sounds-text-underline{
        align-self:center;
        width:5rem;
        height:0.3rem;
        background-color:#E8EBD8;
        margin-top:2em;
    }

}


