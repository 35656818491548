// TopicBibliography

@use '../../assets/styles/colors' as *;



#topic-bibliography{

}

.bibliography-item{
    border-top: 1px solid $col-dark-vapour;
    padding:1.5rem 0;
}

.bibliography-item h3{
    margin-top:0;
    margin-bottom:0;
    font-size:1.6rem;
}

.bibliography-item .author{
    margin:0;
    margin-top:0.7rem;
}
.bibliography-item .publisher{
    margin:0;
    margin-top:0.7rem;
    
}