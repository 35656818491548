// HeroLearners

@use '../../assets/styles/colors' as *;
@use '../../assets/styles/breakpoints' as *;
@use '../../assets/styles/vars' as *;

#bm {
    width: 100%;
    height: 100%;
  }
#hero.audience-learners{
    width:100%;
    max-width:100%;
    overflow:hidden;
    height:40rem;
    background-color:#190E2D;
    z-index:0;
    position:relative;
    display: inline-block;
    @include bp(s){
        height:60vh;
    }
}
#hero.audience-learners .hero-base{
    width:100%;
    // height:200px;
    position:absolute;
    // display: block;
    // top:-4vw;
    bottom:-5px;
    left:0;
    z-index:100;
    img{
        width:100%;
        height:auto;
    }
}

#hero .main-column{
    width:100%;
    height:100%;
    overflow:hidden;
}

#hero-foreground{
    position:absolute;
    width:100%;
    height:100%;
    left:0;
    top:0;
    opacity:1;
    z-index:2;
}

#hero-background{
    position:absolute;
    width:100%;
    height:100%;
    left:0;
    top:0;
    z-index:1;
}

#hero-image{
    width:100%;
    height:100%;
    z-index:1;

}
#hero-image img{
    width:100%;
    height:100%;
    object-fit:cover;
    opacity:0;
}
#hero-overlay{
    position:absolute;
    width:100%;
    height:100%;
    z-index:2;
}
#hero-overlay-scrim{
    position:absolute;
    width:100%;
    height:100%;
    background-color:#40237299;
}

#hero-overlay-gradient-vertical{
    position:absolute;
    width:100%;
    height:100%;
    background: rgb(25,14,45);
    background: linear-gradient(0deg, rgba(25,14,45,0) 81%, rgba(25,14,45,1) 100%); 
}
#hero-overlay-gradient-horizontal{
    position:absolute;
    width:100%;
    height:100%;
    background: rgb(25,14,45);
    background: linear-gradient(90deg, rgba(25,14,45,1) 0%, rgba(25,14,45,0) 29%, rgba(25,14,45,0) 72%, rgba(25,14,45,1) 100%); 
}
#hero-title-container{
    position:absolute;
    width:100%;
    height:100%;
    display:flex;
}
#hero-title{
    margin:auto;
    margin-top:auto;
    z-index:2;
    width:40%;
    
    // background-color:#f80;

    @include bp(s) {
        width:70%;
    }

    @include bp(m) {
        width:55%;
    }
}

#hero-title-logo{
    width:100%;
} 
#hero-title img{
    width:1px;
    height:1px;
    opacity:0;
}

#hero-title h2, #hero-title h3{
    color:#fff;
    text-align:center;
    font-weight:normal;
    font-family:'Petrona';
    font-style: italic;
    
}

#hero-title h2{
    margin:0.5em 0 0 0;
    
}
#hero-title h3{
    margin:0.8em 0 0 0;
    padding:0.8em 0 0 0;
    border-top:1px solid #ffffff33;
    // font-size: $font-size-body-xl;
}


#hero-title #lang{
    background-color:transparent;
}

#hero-title #lang .lang--title{
    display:none;
}