// MODAL MEDIA

@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/breakpoints' as *;



.cookie-consent{
    position:fixed;
    bottom:0;
    // right:0;
    display:flex;
    flex-direction:row;
    padding:0;
    background-color: #ffffffee;
    box-shadow:0 0 1rem #00000011;
    z-index: 50;
    align-content:center;
    width:100%;
    justify-content: center;
    // padding:0.5rem;

   
    @include bp(s){
        flex-direction:column;
    }
}

.cookie-consent p{
    // line-height:1.2em;
    color:$col-dark;
    font-family:$font-snack;
    margin:0;
    align-self:center;
    padding:0.5rem;
}

.cookie-consent-buts{
    margin-left:2rem;
    display:flex;
    flex-direction:row;
    padding:0.5rem;
    justify-content: center;
    @include bp(s){
        justify-content: flex-end;
    }
}
.cookie-consent-but{
    border:0;
    border-radius:0.25rem;
    padding: 0.5rem 0.5rem;
    cursor:pointer;
    font-weight:bold;
    min-width: 7rem;
    display:inline-block;
    // height:100%;
    min-height:3rem;
    transition:all 0.2s;
}



.cookie-consent-but-info{ 
    background-color: transparent;
    color:$col-dark-ghost !important;
    margin-right:1rem;
    
}
.cookie-consent-but-info:hover{
    color:$col-dark !important;
}

.cookie-consent-but-ok{ 
    background-color: $col-highlight;
    color:$col-light !important;
}
.cookie-consent-but-ok:hover{
    opacity:0.9
}