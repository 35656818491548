@use 'colors' as *;
@use 'breakpoints' as *;
@use 'vars' as *;

@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300&family=Merriweather+Sans:wght@300;700&family=Petrona:ital,wght@1,300&display=swap');

@font-face{ 
    font-family: 'BlackerDisplayMedium';
    src: url('../fonts/blacker_pro_display-medium-webfont.eot');
    src: url('../fonts/blacker_pro_display-medium-webfont.eot?#iefix') format('embedded-opentype'),
        url('../fonts/blacker_pro_display-medium-webfont.woff') format('woff'),
        url('../fonts/blacker_pro_display-medium-webfont.ttf') format('truetype'),
        url('../fonts/blacker_pro_display-medium-webfont.svg#webfont') format('svg');
}


// @font-face{ 
//     font-family: 'BlackerTextMedium';
//     src: url('../fonts/blacker-text-medium-trial-webfont.eot');
//     src: url('../fonts/blacker-text-medium-trial-webfont.eot?#iefix') format('embedded-opentype'),
//         url('../fonts/blacker-text-medium-trial-webfont.woff') format('woff'),
//         url('../fonts/blacker-text-medium-trial-webfont.ttf') format('truetype'),
//         url('../fonts/blacker-text-medium-trial-webfont.svg#webfont') format('svg');
// }


html{
    
    
    width:100%;
    min-height:100%;
    display:flex;
    margin:0;
    
    
    

   
}

body {
    margin: 0;
    width:100%;
    min-height:100%;
    display:flex;
    background-color:$col-bg;
    text-align: left;
}
  
#root{
    min-height:100%;
    width:100%;
    display:flex;
}


/* ------------------------------------------------------------------------------------
* TYPOGRAPHY 
*/

html{
    font-family: $font-body;
    font-size: 14px;
    font-weight:100;
    
    

    
    @include bp(m) {
        font-size:14px;
    }
    
    @include bp(s) {
        font-size:14px;
    }
    @include bp(xxs-down) {
        font-size:14px;
    }
  
}


h1, h2{
    font-family:$font-header;
    color:$col-header;
    letter-spacing: -0.01em;
    font-weight:500;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

h3, h4{
    /* font-family:'BlackerTextMedium';  */
    font-family:$font-header;   
    color:$col-header;
    letter-spacing: -0.01em;
    font-weight:500;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

h1{
    font-size:$font-size-h1;
    line-height:1.1em;

    @include bp(s){
        font-size:$font-size-h1-s;
    }
}

h2{
    font-size:$font-size-h2;
    line-height:1.15em;

    @include bp(s){
        font-size:$font-size-h2-s;
    }
}

h3{
    font-size:$font-size-h3;
    line-height:1.3em;
}


h3.grid-item{
    font-size:1.3rem;
}

h3.subtitle{
    margin-top:0.5em;
}

h4{
    font-size:1.6rem;
    line-height:1.28em;
}

h5{
    font-family:$font-ui;
    text-transform: uppercase;
    font-size: $font-size-body;
    letter-spacing:0.1em;
}

p {
    line-height:1.45em;
    font-family:$font-body;
    letter-spacing:0.01rem;
}
p, li, a{
    font-size:$font-size-body;
    @include bp(s){
        font-size:$font-size-body-mobile;
    }
}
p strong, p b{
    font-weight: 900;
}

p.small, li.small, a.small{
    font-size:$font-size-body-small;
    @include bp(s){
        font-size:$font-size-body-small-mobile !important;
    }
}

// Long line length text has greater leading
.text-column p{
    line-height:1.75em;
}


quote{
    display: inline-block;
    padding-left: 3rem;
    border-left:0.2rem solid $col-header-faint;
    font-family: $font-italic;
    font-size: $font-size-body-l;
}

input[type=text]{
    font-family:$font-button;
    letter-spacing:-0.04em;
    // font-weight:600;
}

input[type=text]::placeholder{
    color:$col-dark-ghost;
}

i{
    font-family:$font-italic;
}
h3 i{ 
    font-family:$font-header-italic;
}

p i{
    font-family:$font-body-italic;
}

p.ui{
    font-family:$font-ui;
}
p.ui i{
    font-family:$font-ui;
    font-style:italic;
}
p.ui b, p.ui strong{
    font-family:$font-ui;
    font-weight:900;
}

.text-column p {
    color: $col-dark;
}


a{
    color:$col-link;
    text-decoration:none;
}
a:hover{
    
    text-decoration:underline;
}

.smallcaps{
    font-size:$font-size-body-smallcaps;
    letter-spacing:0.04em;
    text-transform: uppercase;
    font-family: 'Merriweather Sans';
    font-weight:normal;
    @include bp(s){
        font-size:$font-size-body-smallcaps-mobile;
    }
}

.bold{
    font-weight: bold;
}

.faint{opacity:0.65}
.ghost{opacity:0.2}
.vapour{opacity:0.1}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.list-title{
    color:$col-dark;
}

a .list-title,
button .list-title{
    color:$col-header,

}
a.list-title h3:hover{
    text-decoration:underline $col-header;
}


// --------------------------------------
// BUTTONS
.but{
    display:flex;
    flex-direction:row;
    font-family:$font-button;
    font-size:$font-size-body;
    font-weight:bold;
    color:$col-header;
    line-height:1.4em;
    border:0;
    border-radius:2em;
    text-decoration:none;
    background-color:$col-but-type1-bg;
    padding:1rem 1.6rem;
    cursor:pointer;
    height:min-content;
    white-space: nowrap;
    transition:all 0.3s;
    @include bp(xxs-down){
        font-size:$font-size-body-small-mobile !important;
        border-radius:3em;
    }
    @include bp(s){
        font-size:$font-size-body-small-mobile !important;
        border-radius:3em;
    }
}
.but:hover{
    background-color:$col-but-type1-bg-hover;
    padding-left:1.7rem;
    padding-right:1.7rem;
    // transform:scaleX(1.03);
    /* -webkit-box-shadow:0px 12px 20px 0px rgba(62,66,66,0.35);
    -moz-box-shadow: 0px 12px 20px 0px rgba(62,66,66,0.35);
    box-shadow: 0px 12px 20px 0px rgba(62,66,66,0.35); */
}
.but .icon{
    color:$col-header;
}

.but.type2{
    background-color:transparent;
    color:$col-header;
    padding-left:0;
    padding-right:0;
    border-radius:0;
}

.but.type2:hover{
    // background-color:$col-but-type1-bg;
}
.but.type2 span{
    margin-top:0.1em;
}
.but.type2 .icon{
    color:$col-highlight;
    border-radius:50%;
}
.but.type2>span{
    transition: all 250ms ease-in-out;
}
.but.type2:hover>span{
    font-weight:900;
    margin-right:0.2rem;

}
.but.type2>.icon{
    transition: all 250ms ease-in-out;
}
.but.type2:hover>.icon{
    // background-color:$col-highlight;
    // color:$col-light;
}

button.text-only-button {
    background:none;
    border:none;
    margin:0;
    padding:0;
    cursor: pointer;
	font-family: $font-ui;
	font-size: $font-size-body;
}



// --------------------------------------


.title-underline{
    display:flex;
    flex-direction:row;
    justify-content:center;
}

.title-underline span{
    display:flex;
    align-self:center;
    width:5rem;
    height:0.3rem;
    background-color:#E8EBD8;
    margin-top:1.5em;
    margin-bottom:2rem;
}

.warning{
    padding:1rem;
    border:1px solid $col-dark-vapour;
    background-color: $col-dark-imagined;
    border-radius:0.3rem;
}





