// TopicLibrary

@use '../../assets/styles/colors' as *;



#topic-library{

}

.topic-library-item h2{
    margin-top:0.2em !important;
    margin-bottom:0;
}

.topic-library-item .publication{
    margin-top:0.5rem;
    margin-bottom:0;
}

.topic-library-item .author{
    margin:0;
    margin-top:0.7rem;
}
.topic-library-item .publisher{
    margin:0;
    margin-top:0.5rem;
    
}

.topic-library-item .text{
    display:flex;
    flex-direction:column;
}
.topic-library-text-underline{
    align-self:center;
    width:5rem;
    height:0.3rem;
    background-color:#E8EBD8;
    margin-top:2em;
}
