// -----------------------
// FONT FAMILIES
$font-button: "Merriweather Sans", Helvetica, Arial, "_sans";
$font-ui: "Merriweather Sans", Helvetica, Arial, "_sans";
$font-header: "BlackerDisplayMedium", Georgia, "_serif";
$font-italic: "Petrona", Georgia, "_serif";
$font-header-italic: $font-italic;
$font-body: "Merriweather", Georgia, "_serif";
$font-body-italic: $font-body;
$font-snack: $font-header;


// -----------------------
// FONT SIZE
$font-size-h1:3.6rem;
$font-size-h1-s:2.8rem;
$font-size-h2:2.2rem;
$font-size-h2-s:2rem;
$font-size-h3:1.6rem;
$font-size-h4:1.2rem;
$font-size-body-xl:2.5rem;
$font-size-body-l:1.2rem;
$font-size-body-l-mobile:1.2rem;
$font-size-body:1.05rem;
$font-size-body-mobile:1.1rem;
$font-size-body-small:1rem;
$font-size-body-small-mobile:0.9rem;
$font-size-body-xs:0.9rem;
$font-size-body-smallcaps:0.9rem;
$font-size-body-smallcaps-mobile:0.9rem;
$font-size-body-tinycaps:0.865rem;




$nav-height: 6rem;
$nav-height-mobile: 5rem;

$max-readable-width: 50rem;

// Section item thumb image: PORTRAIT
// 508 / 681 ratio
$section-item-image-ratio-portrait: 1.34;

// Section item thumb image: LANDSCAPE
// 508 / 352 ratio
$section-item-image-ratio: 0.6929;

// Section item thumb image: PANAVISION
// 508 / 276 ratio
$section-item-image-ratio-panavision: 0.55;

// Script banner image
// 800 / 270 ratio
$script-banner-image-ratio: 0.3375;

// Media grid thumb image
// 508 / 352 ratio
$media-grid-image-ratio: 0.6929;