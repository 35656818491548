@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;

$control-size:6rem;
$control-icon-size: 4rem;


.waveform-container{
    position:relative;
    display: flex;  
    flex-direction: row;  
    align-content: center;
    justify-content:center;
    height: 200px;
    width: 100%;
    background: $col-green-light;
}

.waveform{
    width: 100%;
    height: 200px;
}   

.waveform-container .controls{
    position:absolute;
    top:0;
    left:0;
    display: flex;
    align-content: center;
    justify-content:center;
    width:100%;
    height:100%;
    pointer-events:none;
    z-index:100;
}

.waveform-loading{
    width:100%;
    height:100%;
    display:flex;
    align-content: center;
    justify-content:center;
}

// .loading .waveform-loading-bg{
//     opacity:0;
// }
.waveform-loading-bg{
    position:absolute;
    width:100%;
    height:100%;
    display:flex;
    align-content: center;
    justify-content:center;
    opacity:1;
}
.waveform-loading-bg-line{
    display:flex;
    align-self:center;
    width:95%;
    height:0.25rem;
    background-color:$col-dark-vapour;
    border-radius:0.125rem;
}

.waveform-loading-controls{
    position:absolute;
    width:100%;
    height:100%;
    display:flex;
    align-content: center;
    justify-content:center;
}

.controls-item{
    display: flex;
    justify-content: center;
    align-self: center;
    width: $control-size;
    height: $control-size;
}
.spinner{
    display: flex;
    justify-content: center;
    align-items: center;
    // background: $col-light;
    // border-radius:50%;
}

.spinner-icon{
    // width:$control-icon-size;
    // height:$control-icon-size;
    width:3rem;
    height:3rem;
}

.play-but{
    
    display: flex;
    justify-content: center;
    align-items: center;
    background: $col-highlight;
    border-radius:50%;
    border: none;
    outline: none;
    cursor: pointer;
    padding-bottom: 3px;  
    color:$col-light;
    pointer-events:all;
    &:hover {
        background: $col-highlight-translucent;
    }
}

.play-but .but-icon{
    width: $control-icon-size;
    height: $control-icon-size;
    color:$col-light;
}