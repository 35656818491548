// TopicSounds

@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/breakpoints' as *;



#topic-sounds{

}

.topic-sounds-list-item{
    border:0;
    border-top: 1px solid $col-dark-vapour;
    border-color:$col-dark-vapour;
    padding:1.5rem 0.5rem;
    background-color:$col-bg;
    text-align:left;
    display:flex;
    flex-direction:column;
    
    width:calc(100% - 1rem);
    cursor:pointer;
    transition:all 200ms ease-in-out;

    &:hover{
        background-color: $col-green-light;
        z-index:900;
        text-decoration: none;
        
        .item-content{
            transform: translateX(0.1rem);
            .external{
                span{
                    text-decoration:underline;
                }
            }
        }
    }

    .item-content{
        display:flex;
        flex-direction:row;
        justify-content:space-between;
        width:100%;
        transition:all 200ms ease-in-out;
        transform: translateX(0rem);
        
        .main-block{   
            width:100%;
            .item-header{
                display:flex;
                flex-direction:row;
                justify-content: space-between;
                width:100%;
                .item-header-text{
                    display:flex;
                    flex-direction:column;
                    justify-content:center;
                    h3{
                        margin-top:0;
                        margin-bottom:0;
                    }

                    
                }
                .item-header-icon{
                    // border-top:1px solid green;
                    // border-top:1px solid black;
                    margin-left:1rem;
                    img{
                        width:6rem;
                    }
        
                }    
            }

            .author{
                .new-line{
                    display:none;
                }
                .divider{
                    display:inline;
                }
            }

            .description{
                margin-top:0.5rem;
                margin-bottom:0;
                
            }   
            

            @include bp(xxs-down){
                .item-header{
                    // border-top:1px solid red !important;
                    flex-direction:column;
                    
                    .item-header-icon{
                        margin-left:0;
                        display:none;
                    }
                }
                .author{
                    .new-line{
                        display:inline;
                    }
                    .divider{
                        display:none;
                    }
                }
                
                
            }
            @include bp(s-down){
                // border-top:1px solid red !important;
                .author{
                    .new-line{
                        display:inline;
                    }
                    .divider{
                        display:none;
                    }
                }
                
                
            }

            .publication{
                margin-top:0.5rem;
                margin-bottom:0;
            }

            .author{
                margin:0;
                margin-top:0.7rem;
                font-weight:normal;
                .role{
                    opacity:0.65;
                    font-weight:normal;
                }
                .divider{
                    margin:0 1em;
                    opacity:0.2;
                }
            }
            

            .publisher{
                margin:0;
                margin-top:0.7rem;
                
            }

            

            .external{
                display:flex;
                flex-direction:row;
                align-content:center;
                // justify-content: center;
                margin-top: 0.7rem;
                img{
                    width:1rem;
                    height:1rem;
                    align-self:center;
                }
                span{
                    font-family:$font-button;
                    font-size: $font-size-body-small;
                    color:$col-header;
                    margin-left:0.4rem;
                    align-self:center;
                }

            }
        }

        
    }
    
}