// MODAL MEDIA

@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/breakpoints' as *;
@use '../../assets/styles/modal' as *;

// Remove cyan outline on ios safari
.makeStyles-paper-4, 
.jss4,
.modal-container>div{
    // border-top:2px solid red;
    outline: transparent;
}

.modal {
    max-width:$max-readable-width;
    
}


#modal-media {
    
}

// .modal-media-video{
//     max-width:1200px;
// }

#modal-media .modal-header{
    background-color: $col-green-light;
}

#modal-media .modal-header .modal-header-text h3{
    color:$col-header;
}

#modal-media .modal-header .but-icon{
    color:$col-header
}

.modal-header .but-icon{
    width:1.7rem;
    height:1.7rem;
}

// .modal-header .but.close-but,
// .modal-header .but.lang-but{
//     padding:1rem;
//     background-color:black;
// }


.modal-header .but.lang-but{
    padding:0.7rem;
}



.modal-header .button-content{
    display: flex;
    flex-direction: row;
}


.modal-header .lang-icon{
    width:2.3rem;
    min-width:2.3rem;
    height:2.3rem;
    background-size:2.3rem;
}

// LANG ICON

.en .modal-header .lang-icon{
    background-image:url(../../assets/images/ui/menu-icon-lang-dark-en.png);
}
.ga .modal-header .lang-icon{
    background-image:url(../../assets/images/ui/menu-icon-lang-dark-ga.png);
}



.modal-media-body{
    transition: opacity 0.2s ease-in-out;
    opacity:1,
}
.modal-media-body.hidden {
    opacity: 0;
}


.modal-media-body .media{
    position: relative;
    width:100%;
    background-color:$col-light,
}


.modal-media-video .media{
    // height:50vh
    width:100%;
}

.modal-media-audio .media{
    height:200px;
}

.modal-media-script .media{
    width:100%;

    img{
        width:100%;
        height: #{width};
        // min-height: calc(#{width} * 2);
    }

    
}
.modal-media-script .media .img-container{
    position: relative;
    width: 100%;
    padding-bottom: #{calc($script-banner-image-ratio * 100) + "%"};
}
.modal-media-script .media img{
    position: absolute;
    max-width: 100%;
}


.modal-media-content{
    flex-direction: row !important;

    @include bp(s){
        flex-direction: column !important;
    }
}

.modal-media-content-main{
    width:73%;
    min-width:73%;
    padding-right:2%;
    @include bp(s){
        width:100%;
        padding-right:0;
    }
}
.modal-media-content-main.no-side-col{
    width:100%;
    padding-right:0;
}
.modal-media-content-side{
    width:25%;
    min-width:25%;
    // margin-left:1rem;
    @include bp(s){
        
        width:100%;
    }
}

.modal-media-text{
    display:flex;
    flex-direction:column;
    padding-bottom:3rem;
}

.modal-media-title h1{
    margin-top:2rem;
    margin-bottom:0;
}

.modal-media-title .location {
    font-family:$font-italic;
    font-style: italic;
    font-size:$font-size-body-l;
    color:$col-dark-faint;
    margin-top:1.3rem;
    display:flex;
}
.modal-media-title  .location-from {
    margin-right:0.3rem;
}




.modal-media-credits{
    margin-top:1rem;
    display:flex;
    flex-direction:row;
}
.modal-media-credits p{
    margin:0;
    padding:0;
}

.credit-row{
    // display:flex;
    // flex-direction:row;
    margin-top:0.3rem;
    border:0;
}

.credit-column{
    // width:10rem;
}
.credit-column.job{
    opacity:0.5;
    // min-width:10rem;
    max-width:15rem;
    padding-right:1rem;
    border:0;
}

.modal-media-description{
    margin-top:1.5rem;
}
.modal-media-description p{
    padding-top:0;
    margin-top:0;
    color: $col-dark;
}

.modal-media-summary{
    display:flex;
    flex-direction:column;
}

.modal-media-story-underline{
    align-self:center;
    width:5rem;
    height:0.3rem;
    background-color:#E8EBD8;
    margin-top:2em;
    margin-bottom:2em;
}

.modal-media-lyrics{
    background-color:$col-green-vapour;
    padding:2rem;
    border-radius:0.5rem;
    margin-top:2rem;
}

.modal-media-lyrics h3{
    margin:0;
    text-align:center;
}


.modal-media-story{
    display:flex;
    flex-direction:column;
    margin-bottom:2rem;
}

.modal-media-grid{
    width:100%;
    display:flex;
    flex-direction:column;
}




