// ERROR SCREEN
@use '../../assets/styles/colors' as *;
@use '../../assets/styles/breakpoints' as *;
@use '../../assets/styles/vars' as *;

#sitemap-screen {
    min-height:100%;
    display:flex;
    flex-direction:column;
}

#sitemap-screen .content{
    width: 100%;
    min-height:50vh;
    margin-top:7rem;
    margin-bottom:5rem;
    // padding:0;
}

.sitemap-form{
    width: 100%;
    padding:0;
}

.sitemap-form textarea{
    width: calc(95% - 8rem);
    // width:100%;
    padding:2rem;

    min-height: 30rem;
    border: 0;
    outline: none;
    border-radius: 1rem;
    background-color: $col-dark-vapour;
}

.sitemap-form.movies{
    margin-top:2rem;
}
#sitemap-screen .status{
    display: flex;
    flex-direction: row;
    // justify-content: center;
    align-content: center;
}
#sitemap-screen .status .spinner{
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
}
#sitemap-screen .status p{
    margin-right: 2rem;
    font-family: $font-button;
    font-size: $font-size-body-xs;
    text-transform: uppercase;
    color:$col-dark-ghost;
    align-self: center;
}

