@use '../../assets/styles/vars' as *;
@use '../../assets/styles/colors' as *;

.media{
    visibility:visible;
}

.media.hide{
    visibility:hidden;
}


.media-comic-container{
    // padding:1rem;
    padding:20px;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    
}

.turn-container{
    position: relative;
    width:100%;
    // background-color: $col-yellow
    //   width: 100%;
    //   height: 100%;
    transition: opacity 0.3s;
    opacity:1;
}

.media-comic-container.resizing .turn-container{
    transition: opacity 0s;
    opacity:0;
}



.turn-controls{
    position: absolute;
    width: 100%;
    height: 100%;
    //   background-color: $col-
    display: flex;
    flex-direction: column;
    z-index: 100;
    
}

.turn-controls-buts{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 5rem;
}
.turn-controls-buts .but-group{
    display:flex;
    flex-direction: row;
    justify-content: center;
}
.turn-controls-buts .but-group .but{
    margin-right: 1rem;
    cursor:pointer;
    
}

.turn-controls-buts .but-group .but.active{
    cursor: default;
    // border: 4px solid $col-highlight;
    background-color: $col-highlight-ghost;
}

.turn-controls-buts .but-group .but img{
    width: 1.7rem;
}

.turn-controls-buts .but.layout-single{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 1px;
    padding-left: 1.3rem;
    padding-right: 0.9rem;
}
.turn-controls-buts .but.layout-double{
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    padding-left: 0.9rem;
    padding-right: 1.3rem;
}

.turn-controls .turn-controls-paging{
    display: flex;
    flex-direction: row;
    width:100%;
    height:100%;
    opacity: 0;
}



.turn-controls-but-prev{
    width: 50%;
    height: 100%;
    background-color: $col-green;
}
.media-comic-container.front-cover .turn-controls-but-prev{
    display:none;
}
.media-comic-container.back-cover .turn-controls-but-prev{
    margin:auto;
}

.media-comic-container.back-cover.single .turn-controls-but-prev{
    margin:0;
    width: 100%;
}

.media-comic-container.back-cover.double .turn-controls-but-prev{
    margin:auto;
}


.turn-controls-but-next{
    width: 50%;
    height: 100%;
    background-color: $col-blue;
}
.media-comic-container.front-cover.single .turn-controls-but-next{
    margin:0;
    width: 100%;
}
.media-comic-container.front-cover.double .turn-controls-but-next{
    margin:auto;
}
.media-comic-container.back-cover .turn-controls-but-next{
    display:none;
}

.turn-controls-but-prev, 
.turn-controls-but-next{
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    outline: none;
    cursor:pointer;
}

html.no-hover .turn-controls-but-prev, 
html.no-hover .turn-controls-but-next{
    cursor:default;
}


.turn-controls-but-open-container{
    position:absolute;
    width:100%;
    bottom:0;
    height:0;
}
.turn-controls-but-open{
    position:relative;
    top:-4rem;
    margin-left:auto;
    margin-right:auto;
    background-color: $col-header-translucent;
    display:flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    border-radius:10rem;
    cursor:pointer;
    padding:1.5rem;
    min-width:4rem;
    min-height:4rem;
    width:fit-content;
    transition:all 0.3s;
    // scale:1;
    transform: scale(1);
    opacity:1;
    cursor:pointer;
}
.turn-controls-but-open.unrevealed{
    // scale:0.5;
    transform: scale(0.5);
    opacity:0;
    cursor:auto;
}

.turn-controls-but-open:hover{
    // scale:1.03;
    transform: scale(1.03);
    background-color: $col-header;
}

.turn-controls-but-open img{
    width:2.5rem;
    height:2.5rem;
    align-self:center;
}

.turn-controls-but-open span{
    font-family:$font-button;
    font-size:$font-size-body;
    font-weight:bold;
    color:$col-light;
    margin-top:0.3rem;
    align-self:center;
}

  

.magazine {
    margin: 0 auto;
    margin-top: 5rem;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 0;
    box-shadow: 0 20px 40px #00000011;
    background-color: transparent;
    transition: width 0.1s;
    width:100% !important;
    
}

.media-comic-container.front-cover.double .magazine, 
.media-comic-container.back-cover.double .magazine {
    width:50% !important;
}
  
.magazine .page {
    height: 100%;

}

.turn-page-wrapper{
    // overflow:visible !important;
}

.magazine .page img {
    max-width: 100%;
    height: 100%;
    border-radius:5px;
    
    
    
}



