@use 'colors' as *;
@use 'vars' as *;
@import 'breakpoints';



.main-column{
    margin:0 auto;
    position:relative;
    width:1280px;
    max-width:1280px;
    // min-width:240px;

    @include bp(s){
        max-width:640px;
        width:100%;
    }

    @include bp(m){
        max-width:1024px;
        width:100%;
    }

    @include bp(l){
        max-width:1280px;
        width:100%;
    }
    
}
.main-column--narrow{
    max-width:1024px;

    @include bp(s){
        max-width:640px;
        width:100%;
    }

    @include bp(m){
        max-width:1024px;
        width:100%;
    }

    @include bp(l){
        max-width:1024px;
        width:100%;
    }
}

.main-column-content{
   

    @include bp(s){
        padding-left:20px;
        padding-right:20px;

    }

    @include bp(m){
        padding-left:40px;
        padding-right:40px;
    }

    @include bp(l){
        padding-left:40px;
        padding-right:40px;
    }

    @include bp(xl){
        padding-left:40px;
        padding-right:40px;
    }
}

.main-column-under-nav{
    padding-top: $nav-height;
}

.text-column{
    max-width:$max-readable-width;
}

.slick-track{
    margin-left:unset !important;
    margin-right:unset !important;
}