// Nav

@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/breakpoints' as *;

$reveal-dur:0.5s;
$reveal-dur-instant:0s;

#shortcuts-container{
    position:sticky;
    top:$nav-height;
    z-index:2;
    text-align: center;
    background-color: $col-header;
    max-width:100%;
    @include bp(xxs-down){
        top:$nav-height-mobile;
    }

    #shortcuts-sentinel--top{
        position:absolute;
        width:1px;
        height:calc($nav-height + 1px);
        top: calc(-1 * calc($nav-height + 1px));
        
    
        @include bp(xxs-down){
            height:calc($nav-height-mobile + 1px);
            top: calc(-1 * calc($nav-height-mobile + 1px));
        }
    }

    .shortcuts::-webkit-scrollbar {
        display: none;
    }

    &.dark{
        background-color: $col-bg;
        .shortcuts{
            background-color: transparent;
        }
    }

    &.instant .shortcuts{
        transition-duration:$reveal-dur-instant;
    }



    .shortcuts{
        background-color: transparent;
        max-width:100%;
        // max-width:50rem;
        // max-width:100%;
        // border:1px solid red;
        // height:200px;
        transition: all $reveal-dur;
        // display:flex;
        // flex-direction:column;
        // align-content:center;
        // justify-content:center;
        display:inline-block;
        vertical-align:bottom;
        height:100%;
        margin: 0 auto;
        
        padding-top:1rem;
        padding-bottom:1rem;
        overflow-x:scroll;
        overflow-y:hidden;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        text-align:center;
        // width:100%;
        
        
        @include bp(xxs-down){
            top:$nav-height-mobile;
        }
    
        .shortcuts-items-container{
    
            display:flex;
            flex-direction:row;
            height:100%;
            width:100%;
            // border-top:2px solid red;
            // .top-but{
            //     display:flex;
                
            //     // align-self:center;
            //     width:0;
            //     margin:0;
            //     padding:0;
            //     background-color: transparent;
            //     border:0;
            //     border-radius:0;
            //     cursor:default;
            //     transition:all $reveal-dur, background-color $reveal-dur, margin-left $reveal-dur-instant;
            //     flex-grow:1;
                
                
            //     width:3rem;
            //     min-width:3rem;
            //     // border-top:1px solid green;
            //     margin-left:-3rem;

            //     .top-but-icon{
            //         width:0;
            //         margin:0;
            //         padding:0;
            //         transition:all $reveal-dur;
            //         opacity:0;
            //         align-self:center;

            //         width:3rem;
            //         height:2rem;
            //         min-width:3rem;
            //         // border-top:1px solid orange;
            //         img{
            //             width:0rem;
            //             height:0rem;
            //             transition:all $reveal-dur;

            //             width:2rem;
            //             min-width:2rem;
            //             // border-top:1px solid black;
            //         }
            //     }
            // }
            
    
            
            .shortcuts-items{
                width:100%;
                max-width:55rem;
                padding-top:0.5rem;
                // overflow:hidden;
                display:flex;
                flex-direction:row;
                // align-self:center;
                align-content:flex-start;
                justify-content:flex-start;
                // width:100%;
                // padding:0 40px;
                padding:0;
                // border-top:2px solid green;
        
                @include bp(xxs-down){
                    padding:0 0;
                }
        
                @include bp(s){
                    padding:0 0;
                }
        
                @include bp(m){
                    // padding:0 20px;
                }
        
                @include bp(l){
                    // padding:0 30px;
                }  
        
                
            }
        }
    
        
        
    }

    // --------------------------------------------
    // STUCK
    &.stuck{
        background-color: $col-header-near-solid;
        .shortcuts{
            padding-top:0rem;
            padding-bottom:0rem;
            // padding-left:1rem;
            .shortcuts-items-container{
                .top-but{
                    // height:100%;
                    padding: 0;// 0.5rem;
                    // width:3rem;
                    // min-width:3rem;
                    opacity:1;
                    margin-left:0;
                    transition:all $reveal-dur, background-color $reveal-dur-instant, margin-left $reveal-dur;

                    cursor:pointer;
                    .top-but-icon{
                        
                        padding:0;
                        // width:3rem;
                        // min-width:3rem;
                        opacity:1;
                        transform:scale(1);
                        ;
                        img{
                            height:2rem;
                            
                        }   
                    }

                    &:hover,
                    &:active{
                        background-color:$col-header-dark-faint;
                        .top-but-icon{
                            transform:scale(1.1);
                        }
                    }
                    
                }
                
                

                .shortcuts-items{
                    padding:0;
                    @include bp(xxs-down){
                        padding:0;
                    }
            
                    @include bp(s){
                        padding:0;
                    }
            
                    @include bp(m){
                        padding:0;
                    }
            
                    @include bp(l){
                        padding:0;
                    } 
                }
            }
        }
    }

    // ------------------------------------------------------------
    // DARK (LEARNERS)


    &.stuck.dark{
        background-color: $col-bg-dark-translucent;

        .shortcuts-items-container{
            
            .top-but:hover{
                background-color:$col-bg-darker;

                
            }
        }
    }   
}