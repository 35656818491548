// MEDIA GRID

@use '../../assets/styles/colors' as *;
@use '../../assets/styles/breakpoints' as *;


.media-grid{
    display:flex;
    flex-direction:column;

   

    flex-wrap: wrap;
    margin-top:2rem;
    padding-left:1rem;
    border-left:1px solid $col-dark-vapour;

    @include bp(s){
        flex-direction:row;
        padding-left:0;
        border-left:none;
    }
}