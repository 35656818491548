// MODAL THANKS
@use '../../assets/styles/colors' as *;
@use '../../assets/styles/breakpoints' as *;
@use '../../assets/styles/modal' as *;



#modal-thanks .modal-header{
    background-color: $col-green-light;
}


#modal-thanks .modal-header-text h3{
    color: $col-header;
}

#modal-thanks .modal-header .but-icon{
    
    color: $col-header;
}

#modal-thanks .modal-content{
    padding:0;
    // padding-bottom:6rem;
}
#modal-thanks .modal-body{
    background-color: $col-green-imagined;
}

#modal-thanks .intro{
    padding: 0 2rem;
}

#modal-thanks .section{
    padding: 0 2rem;

    margin:0;
    display:flex;
    width:unset;
    flex-direction:column;
}

#modal-thanks h3{
    text-align: center;
    margin-bottom: 0.6em;
}
#modal-thanks .section .underline{
    background-color: $col-highlight;
    width: 3rem;
    height: 0.2rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
}



.names-lists{
    display:flex;
    flex-direction:row;
    flex-wrap: wrap;

    @include bp(s){
        flex-direction:column;
    }
}


#modal-thanks ul.names-list{
    width:45%;
    padding:0;
    padding-right:5%;
    // min-width:10rem;

    @include bp(s){
        width:100%;
        padding:0;
        margin-top: 0;
        margin-bottom: 0;
    }
}

// Sections with 1 list and 3 columns
#modal-thanks .production ul.names-list,
#modal-thanks .programmers ul.names-list{
    width:100%;
    padding:0;
    display:flex;
    flex-direction:row;

    @include bp(s){
        flex-direction:column;
    }
}


#modal-thanks li.item{
    list-style: none;
    width: unset;
    display:flex;
    flex-direction:column;
    padding:0.3rem 0;
    color: $col-dark;
}

// Items in sections with 1 list and 3 columns
#modal-thanks .production li.item,
#modal-thanks .programmers li.item{
    width:30%;
    padding-right:3%;

    @include bp(s){
        width:100%;
        padding-right:0;
        margin-top:1rem;
    }
}
#modal-thanks .programmers li.item{
    @include bp(s){
        margin-top:0;
    }
}

#modal-thanks .production li.item:first-child{
    @include bp(s){
        margin-top:0;
    }
}


#modal-thanks .production .item-name{
    // font-weight:bold;
    text-align:center;
}

#modal-thanks .production .item-title{
    opacity:0.5;
    text-align:center;
    margin-top:0.3em;
    
}


#modal-thanks .pic{
    width:100%;
    
    position:relative;
    display:flex;
    flex-direction:row;
    align-content: center;
    justify-content: center;
}



#modal-thanks .pic img{
    width:100%;
    display:block;
}

#modal-thanks .pic.frog{
    margin-top:2rem;
    margin-bottom:-1rem;
    // height:12rem;
    justify-content: flex-start;
    // background-color: $col-green-vapour;
}
#modal-thanks .pic.frog .image-container{
    width:12rem;
    margin-left:1rem;
}

#modal-thanks .pic.piper{
    align-content: flex-end;
    justify-content: flex-end;
    // height:10rem;
    margin-top:-3rem;
}

#modal-thanks .pic.piper .image-container{
    width:12rem;
}


#modal-thanks .pic.wise-fool{
    margin-top:2rem;
    align-content: center;
    // height:20rem;
}
#modal-thanks .pic.wise-fool .image-container{
    width:12rem;
}


#modal-thanks .pic.bagpipe{
    margin-top: 2rem;
    justify-content: flex-start;
}

#modal-thanks .pic.bagpipe .image-container{
    width:12rem;
    margin-left:10vw;
    transform: rotate(-5deg);
}



#modal-thanks .pic.sheep{
    margin-top:2rem;
    margin-bottom:1rem;
}

#modal-thanks .pic.sheep .image-container{
    width:12rem;
}

#modal-thanks .pic.fairy{
    align-content: flex-end;
    justify-content: flex-end;
    height:10rem;
}

#modal-thanks .pic.fairy .image-container{
    width:7rem;
    margin-right:10%;
    transform: rotate(10deg);
}


#modal-thanks .pic.school{
    margin-top:4rem;
    margin-bottom:-1rem;
    // height:16rem;
    justify-content: flex-start;
    // background-color: $col-green-vapour;
}

#modal-thanks .pic.school .image-container{
    width:12rem;
}



#modal-thanks .pic.otter{
    margin-top:2rem;
    // height:20rem;
}

#modal-thanks .pic.otter .image-container{
    width:10rem;
}



#modal-thanks .logos{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-self: center;
    align-content: center;
    justify-content: center;
    margin-bottom: 4rem;
}
#modal-thanks .logo{
    width: 8rem;
    // height: intrinsic;
    // margin-right: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
    margin-top: 2rem;
    align-self: center;
}
#modal-thanks .logo-wha{
    width: 9rem;
}
#modal-thanks .logo-hie{
    width: 10rem;
}
#modal-thanks .logo-cnag{
    width: 6rem;
}
#modal-thanks .logo-gms{
    width: 12rem;
}
#modal-thanks .logo-leader{
    width: 5rem;
}
.bottom-underline{
    background-color: $col-highlight-vapour;
    width: 30%;
    height: 0.4rem;
    margin-top:2rem;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
}

