// TopicTabs

@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/breakpoints' as *;



#tabs-inner>.main-column>.main-column-content{
    
    // Remove padding from tabs when on mobile
    @include bp(s){
        padding:0;
    }
}

.MuiTab-wrapper{
    color:$col-header !important;
    font-family: $font-button;
    font-weight: bold;
}

.MuiTabs-indicator{
    background-color: $col-highlight-faint !important; 
    height:5px !important;
}


