// TOPIC SCREEN
@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/breakpoints' as *;


#topic-screen {
    // min-height:150vh;
    display:flex;
    flex-direction:column;
}

#topic-screen-content{
    display:flex;
    flex-direction:column;
    min-height:100vh;
}

.loader-container{
    display:flex;
    flex-direction: column;
    align-content: center;
    justify-content:center;
    width:100%;
    height:100%;
    min-height:100vh;
}
.loader{
    width:3rem;
    height:3rem;
    align-self:center;
}

.tabs{
    margin-top:2rem;
    width:100%;
    min-height:50vh;
    padding-bottom:5rem;
}

#topic-screen .topic-banner-container{
    margin-top: calc($nav-height + 1rem);
    width: 100%;
    display: flex;
    flex-direction: column;
    opacity: 0;
    transition: opacity 0.6s;
}

#topic-screen.topic-loaded .topic-banner-container{
    opacity: 1;

}

#topic-screen .topic-banner {
    
    background-color: $col-green-vapour;
    display: block;
    

    img{
        margin: 0 auto;
        // width: 100%;
        max-width: 100%;
        max-height:50vh;
        transition: all 0.3s;
        align-self: center;
        display: block;
    
        @include bp(s){
            // width: 100%;
            max-height:unset;
        }
    
        @include bp(m){
            width: 75%;
            max-height:unset;
        }
    }
    
}
.topic-content{
    opacity:0;
    transition: opacity 0.3s;
}
#topic-screen.topic-loaded .topic-content{
    opacity:1;
}



#topic-screen .topic-header.no-banner{
    margin-top: $nav-height + 2rem;
}

#topic-screen .topic-banner-container .topic-banner--credit{
    font-size:$font-size-body-xs;
    font-family: $font-button;
    display:flex;
    flex-direction: column;
    text-align:right;
    margin-top:0.5rem;
    color:$col-dark-ghost;
    text-transform:uppercase;
    
}

#topic-screen .topic-header{
    margin-top:2rem;
}


#topic-screen .topic-header h1 {
    margin-top:0;
    margin-bottom:0;
}

#topic-screen .topic-header h2 {
    margin:0;
    margin-top:1rem;
    font-size:1.8rem;

}
.topic-subheader{
    margin-top:2rem;
}

#topic-screen .topic-header .location {
    font-family:$font-italic;
    font-style: italic;
    font-size:$font-size-body-l;
    color:$col-dark-faint;
    margin-top:1rem;
    display:flex;

    @include bp(s){
        font-size:$font-size-body-l-mobile;
    }
}
#topic-screen .topic-header .location-from {
    margin-right:0.3rem;
}


.spinner{
    width:100%;
    height:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-content:center;
}

.spinner>div{
    display:flex;
    flex-direction:column;
    align-self:center;
}