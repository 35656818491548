// MODAL PRIVACY
@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/modal' as *;


#modal-privacy .modal-header{
    background-color: $col-green-light;
}


#modal-privacy .modal-header-text h3{
    color: $col-header;
}

#modal-privacy .modal-header .but-icon{
    
    color: $col-header;
}

#modal-privacy .modal-body{
    background-color: $col-green-imagined;
}
#modal-privacy section{
    margin-top:2rem;
    border-top: 1px solid $col-dark-vapour;
}
#modal-privacy section:first-child{
    border:0;
}

#modal-privacy section p{
    font-size: $font-size-body-small;
    color: $col-dark;
}

#modal-privacy section.footnotes{
    margin-top:2rem;
    border-top: 1px solid $col-dark-vapour;
}
#modal-privacy section.footnotes p{
    font-size: $font-size-body-small;
    color: $col-dark-ghost;
}
#modal-privacy h2{
    margin-top: 2rem;
    font-size:calc(#{$font-size-body-xl} * 0.7);
}
#modal-privacy h3{
    margin-top:2rem;
    font-size:$font-size-body-l;
}
