// Home Screen
@use '../../assets/styles/colors' as *;
@use '../../assets/styles/breakpoints' as *;
@use '../../assets/styles/vars' as *;


#home-screen {
    
    min-height:100%;
    display:flex;
    flex-direction:column;
    opacity:1;
    transition: opacity 300ms;
}

#home-screen.hidden{
    opacity:0;
}



#sections{
    position:relative;
    margin-top:0;
    margin-bottom:5em;
    width:100%;
    z-index:1;
    display:flex;
    // background-color:#f8f8f8;
    flex-direction:column;
}





