// Nav

@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/breakpoints' as *;



#nav {
    
    // We use 100vw because if we use 100%, opening a modal causes the nav #menu element to 
    // jump right by 15px (due to the modal library causing the scroller to disappear).
    // Note: 100vw includes any scrollbar in the width, which we must remember when placing items on the right.
    width:100vw;
    
    
    position:fixed;
    display:flex;
    flex-direction:column;
    height:$nav-height;
    z-index:100;
    top:0;
    // border-bottom:2px solid red;
    @include bp(xxs-down){
        height:$nav-height-mobile;
    }
}


.topline{
    width:100%;
    height:5px;
    background-color:$col-highlight;
    position:relative;
}

#nav .main-column{
    height:100%;
}

#nav .main-column-content{
    // width:100%;
    height:100%;
}

#nav--inner{
    width:100%;
    height:100%;
    display:flex;
    flex-direction:row;
    justify-content: space-between;
}

.nav--inner-nologo{
    justify-content: flex-end !important;
}


// ------------------------------------------------
// SHADOW

.nav--shadow{
    width:100%;
    height:$nav-height;
    background: rgb(25,14,45);
    background: linear-gradient(0deg, rgba(25,14,45,0) 0%, rgba(25,14,45,1) 87%, rgba(25,14,45,1) 100%);
    
    position:absolute;
    opacity:0.8;
}
.dark .nav--shadow{
    display:none;
}

// ------------------------------------------------
// BG
.nav--bg{
    position:absolute;
    width:100%;
    height:100%;
    background-color:$col-light;
    
    opacity:0;
    // box-shadow:0 0.1rem 0.8rem #00000014;
    transition: all 0.2s ease-in-out;
}

.screen-home .nav--bg{
    background-color:$col-header;
}

#nav.dark .nav--bg{
    background-color:$col-light;
}



// ------------------------------------------------
// LOGO

// Hide logo all the time on the home screen for kids
.audience-kids.screen-home #nav--logo, 
.audience-toddlers.screen-home #nav--logo{
    display:none
}

// Hide logo on Home screen. 
// Revealed when scrolling down, learner audience only.
.screen-home #nav--logo{
    opacity:0;
}

#nav--logo{
    width:7rem;
    min-width:7rem;
    // background-color:$col-highlight;
    display:flex;
    align-self:center;
    transition: all 0.3s ease-in-out;
}

#nav--logo a{
    text-decoration:none;
    border:0;
    display:block;
    // padding:1rem 1.2rem;
}
#nav--logo a:hover{
    border:0
}

#nav--logo img{
    width:100%;
}

// ------------------------------------------------
// KIDS NAV

#nav--kids{
    display:none;
    
}


.screen-home.audience-kids #nav--kids, 
.screen-home.audience-toddlers #nav--kids {
    display:flex;
    flex-direction:row;
    @include bp(s){
        margin-left:-2rem;
    }
}

.nav--kids--item{
    // transition: all 1s ease-in-out;
    
}

.nav--kids--item .button{
    display:flex;
    flex-direction:column;
    align-content: center;
    justify-content:center;
    text-decoration:none;
    color:$col-light;
    font-weight:bold;
    font-size:1rem;
    font-family:$font-button;
    cursor:pointer;
    transition:all 0.3s ease-in-out;
    border:0;
    padding:0 1rem;
    background-color: transparent;
    border-radius:0 0 4rem 4rem;
    // transition: all 1s ease-in-out;
    @include bp(s){
        padding:0 0.5rem;
    }
}

.dark .nav--kids--item .button{
    color:$col-header;
}


// Active: kids
.audience-kids .nav--kids--item.audience-kids .button{
    background-color: #B43D92;
    color:$col-light;
}

// Active: toddlers
.audience-toddlers .nav--kids--item.audience-toddlers .button{
    background-color: #B43D92;
    color:$col-light;
}

.nav--kids--item .button-content{
    text-align:center;
    margin-top:1rem;
    transition: all 0.3s;
    display:flex;
    flex-direction:column;
    align-content: center;
    justify-content:center;
}


.nav--kids--item span{
    align-self:center;
    // margin-top:1rem;
    transition: all 0.3s ease-out;

    @include bp(s){
        font-size:$font-size-body-small-mobile;    
    }
    @include bp(xxs-down){
        font-size:$font-size-body-small-mobile;  
    }
}
.nav--kids--item span:hover{
    text-decoration: none;
}

.nav--kids--item img{
    width:6rem;
    height:calc(6rem * (60/80));
    transition:all 0.3s ease-in-out;
    align-self:center;

    @include bp(xxs-down){
        width:5rem;
        height:calc(5rem * (60/80));
    }
}
.nav--kids--item img:hover{
    transform: scale(1.08);
}

// ------------
// Kid's buttons when in the top position (called "dark")

.dark .nav--kids--item .button-content{
    margin-top:0;
}
.dark .nav--kids--item span{
    // display:none;
    // height:0px;
    opacity:0;
    // transform:scale(0);
    margin-top:-1.5rem;
    
}
.dark .nav--kids--item .button img{
    width:5rem;
    height:calc(5rem * (60/80));
    margin-top:1.3rem;
    padding-bottom:0.2rem;
}







// ------------------------------------------------
// MENU
#menu{
    display:flex;
    align-self:flex-end;
    justify-content:center;
    align-content:center;
    height:100%;
    margin-right:-1rem;
    // border:1px solid black;
    @include bp(s){
        margin-right:0rem;
    }
}
#menu ul{
    margin:0;
    display:flex;
    flex-direction:row;
    list-style-type: none;
    padding:0;
    align-self:center;
}

#menu li{
    margin-left:2rem;

    @include bp(s){
        margin-left:0rem;
    }
}
#menu li:first-child{
    margin-left:0;
}

// Hide exit-kids button when on learners view
.audience-learners #menu-exit-kids{
    display:none;
}

// Hide kids button when on kids view
.audience-kids #menu-kids, 
.audience-toddlers #menu-kids{
    display:none;
}

#menu li .button{
    display:flex;
    flex-direction:row;
    color:$col-light;
    text-decoration: none;
    font-weight:bold;
    font-size:1rem;
    font-family:$font-button;
    justify-content:center;
    align-content:center;
    opacity:1;
    transition: all 300ms ease-in;
    border-radius:4px;
    // padding:0 0.5rem;
    padding: 0.5rem 1.1rem;
    border:0;
    background-color:$col-highlight-none;
    cursor:pointer;

    @include bp(s){
        margin-left:0.5rem;
        padding: 0.5rem;
    }

    @include bp(xxs-down){
        margin-left:0.5rem;
        padding: 0.5rem;
    }
   

}

#menu li:first-child .button{
    margin-left: 0;   
}
#menu li:last-child .button{
    // padding-right: 0;   
}
#nav.dark #menu li .button{
    color:$col-header;
    // background-color:transparent;
    
}



#menu li .button:hover{
// #menu li .button:active{
    text-decoration:none;
    border:0;
    background-color:$col-highlight;
    
}


#nav.dark #menu li .button:hover{
// #nav.dark #menu li .button:active{
    background-color:$col-highlight-vapour;
    // background-color:green;
}

.touch #menu li .button:hover, 
.touch #nav.dark #menu li .button:hover{
    // background-color:transparent
}

#nav #menu li .button:active{
    background-color:transparent;
}

#nav #menu li .button:visited{
    background-color:transparent;
}

#menu li .button .button-content{
    display: flex;
    flex-direction: row;
}



#menu li .button span{
    align-self:center;
    margin-left:0.5em;
    text-align: left;

    @include bp(s){
        font-size:$font-size-body-mobile; 
        line-height:1em;  
        display:none;
    }

    @include bp(xxs-down){
        display:none;
        
    }
}




.menu-icon{
    width:2.5rem;
    min-width:2.5rem;
    height:2.5rem;
    background-size:2.5rem 2.5rem;
}

// LANG ICON
#nav.en #menu-lang .menu-icon{
    background-image:url(../../assets/images/ui/menu-icon-lang-light-en.png);
}
#nav.ga #menu-lang .menu-icon{
    background-image:url(../../assets/images/ui/menu-icon-lang-light-ga.png);
}
#nav.dark.en #menu-lang .menu-icon{
    background-image:url(../../assets/images/ui/menu-icon-lang-dark-en.png);
}
#nav.dark.ga #menu-lang .menu-icon{
    background-image:url(../../assets/images/ui/menu-icon-lang-dark-ga.png);
}

// ORAL TRADITIONS ICON
#nav #menu-oral .menu-icon{
    background-image:url(../../assets/images/ui/menu-icon-oral-light.png);
}
#nav.dark #menu-oral .menu-icon{
    background-image:url(../../assets/images/ui/menu-icon-oral-dark.png);
}


#nav .text-separator{opacity:0.3;}

#nav .text{transition:all 0.5s;}

#nav.en .text-en{ border-bottom:2px solid $col-light; }
#nav.dark.en .text-en{ border-bottom:2px solid $col-header; }
#nav.en .text-ga{ border-bottom:2px solid transparent; }

#nav.ga .text-ga{ border-bottom:2px solid $col-light; }
#nav.dark.ga .text-ga{ border-bottom:2px solid $col-header; }
#nav.ga .text-en{ border-bottom:2px solid transparent; }

// KIDS ICON
#menu-kids .menu-icon{
    background-image:url(../../assets/images/ui/menu-icon-kids-light.png);
}
#nav.dark #menu-kids .menu-icon{
    background-image:url(../../assets/images/ui/menu-icon-kids-dark.png);
}

// EXIT KIDS ICON
#menu-exit-kids .menu-icon{
    background-image:url(../../assets/images/ui/menu-icon-exit-kids-light.png);
}
#nav.dark #menu-exit-kids .menu-icon{
    background-image:url(../../assets/images/ui/menu-icon-exit-kids-dark.png);
}

// SEARCH ICON
#menu-search .menu-icon{
    background-image:url(../../assets/images/ui/menu-icon-search-light.png);
}
#nav.dark #menu-search .menu-icon{
    background-image:url(../../assets/images/ui/menu-icon-search-dark.png);
}
