// Nav

@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/breakpoints' as *;

#footer{
    width:100%;
    background-color:$col-header;
    // padding:2rem 0;
    padding:0;

}
.footer-top{
    width: 100%;
    height: 8rem;
    background-image: url('../../assets/images/ui/footer-top.png');
    background-repeat: repeat-x;
    background-position: bottom;
    background-size: 60rem calc(60rem * 0.122);
    background-color:$col-bg;
    // padding-top: 2rem;
}
#footer .main-column{
    // padding-top: 2rem;
    padding-top: 0;
}

.footer-row{
    display:flex;
    flex-direction:row;
    border-top:1px solid $col-light-vapour;
    flex-wrap: wrap;
}

.footer-row-top{
    // margin-top:2rem;
    // border-top:1px solid red;
    position: relative;
    overflow: hidden;
    overflow-x:hidden;
    padding-top: 7rem;
    margin-top: -4rem;
    // margin-right:-10rem;
    // padding-top:-2rem;
    justify-content: space-between;
}

.footer-col-char{
    display:flex;
    
}
.audience-learners .footer-col-char{
    display: none;
}

.footer-col-char img{
    position:absolute;
    right: 0;
    bottom:-2rem;
    width: 20rem;
}
.footer-row-bottom{
    margin-top:2rem;
}

.footer-col{
    display:flex;
    flex-direction:column;
    margin-right:2rem;
    width:31%;
    
    padding-top:2rem;

    @include bp(s){
        min-width:100%;
    }

    @include bp(m){
        min-width:40%;
    }

}

.footer-row-top .footer-col{
    min-width:40%
    
}
.footer-col:last-child{
    margin-right:0;
}


#footer p{
    color:$col-light-faint;
    margin-top:0;
    font-size:$font-size-body-small;
}

#footer a{
    text-decoration: none;
    color:$col-light;
    font-size:$font-size-body-small;
    opacity:1;
    transition: all 0.25s;
    transform:scale(1)
}
#footer a:hover{
    // text-decoration:underline;
    opacity:1;
    // padding-left: 0.1em;
    // transform:scale(1.01)
}


#footer-logo{
    width:7rem;
    margin-top:0;
}

.footer-row-top p{
    
}
.footer-col-logo{
    padding-top:0;
}

#footer .footer-col-logo p{
    font-size:1.2rem;
    margin-top:0.5em !important;
    font-family:$font-italic;
    font-size:$font-size-body-l;
}

#footer h3{
    // font-family:'BlackerDisplayMedium';  
    color:$col-light;
    font-size:1.4rem;
    margin-top:0;
    /* margin-bottom:0; */
}


.footer-hello{
    display:flex;
    flex-direction:row;
}


.footer-hello-icon{
    font-size: 2.5rem !important;
    margin-right:1rem;
}

.footer-hello-icon:hover{
    transform:scale(1.03)
}


.footer-col-copyright{
    width:100%;
}
.footer-col-copyright .separator{
    padding:0 1em;
    opacity:0.5
}

.footer-row-bottom{
    padding-top:1rem;
}

.footer-col-copyright p{
    font-size:$font-size-body-small;
    opacity:1;
    transition:all 0.5s;
}
.footer-col-copyright p:hover{
    opacity:1;
    
}
#footer .footer-col-copyright p a{
    // opacity:0.8;
}
#footer .footer-col-copyright p a:hover{
    // opacity:1
    // transform:scale(1.01)
}

#footer .lang--buttons{
    display:flex;
    flex-direction:row;
    align-self:center;

}
#footer .lang--buttons{
    display:flex;
    flex-direction:row;
    align-self:flex-start;
}
#footer .lang--buttons .button{
    border-radius:1.5rem;
    font-size:1rem;
    font-family: $font-button;
    font-weight:bold;
    border:0;
    padding:0.8rem 0.8rem;
    margin-left:0.1rem;
    // min-width:unset;
    background-color: $col-light-wraith;
    color:$col-light;
    cursor: pointer;
    border-radius:0 .5rem .5rem 0;
    text-align: center;
    opacity:1;
    // padding-left:1rem;
    // padding-right:1rem;
    @include bp(xxs-down){
        font-size:$font-size-body-small-mobile;
        padding:1rem 0.7rem;
    }
    @include bp(s){
        font-size:$font-size-body-small-mobile;
        padding:0.8rem 0.7rem;
    }
}

#footer .lang--buttons .button:first-child{
    margin-left:0;
    border-radius:.5rem 0 0 .5rem;
}
#footer .lang--buttons .button:hover, #footer .lang--buttons .button:focus {
    background-color: $col-highlight-faint;
}
#footer .lang--buttons .button.active, #footer .lang--buttons .button:active {
    background-color: $col-highlight-but;
    // background-color: black;
}



