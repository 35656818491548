// ERROR SCREEN
@use '../../assets/styles/colors' as *;
@use '../../assets/styles/breakpoints' as *;

#error-screen {
    min-height:100%;
    display:flex;
    flex-direction:column;
}

.content{
    min-height:50vh;
    margin-top:7rem;
    margin-bottom:5rem;
}