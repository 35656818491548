// MODAL SCREEN

.modal-container{
    overflow:scroll;
    max-height:100vh;
    width:100vw;
}
.modal-container-scrim{
    position:absolute;
    width:100%;
    height:100%;
}
