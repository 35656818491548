// SectionRow

@use '../../assets/styles/colors' as *;
@use '../../assets/styles/breakpoints' as *;
@use '../../assets/styles/vars' as *;

.row{
    margin-top:2rem;
    overflow:hidden;
    display:flex;
    flex-direction:column;
    align-content:center;
    justify-content:center;
    /* width:100%; */
    padding:0 40px;
    @include bp(s){
        padding:0 20px;
    }

    @include bp(m){
        padding:0 20px;
    }

    @include bp(l){
        padding:0 30px;
    }
}

.row-header{
    display:flex;
    flex-direction:row;
    justify-content: flex-start;

    padding:0 40px;
    @include bp(s){
        padding:0 20px;
    }

    @include bp(m){
        padding:0 20px;
    }

    @include bp(l){
        padding:0 30px;
    }
}

/* When we jump to a row anchor it's underneath the top nav. 
 * Shift it up the page relative to the row's title	.
 */
div.row-anchor {
	position: relative;
	top: -10.5rem;
}

.audience-learners .row-header{
    margin-top:2rem;
}


.row-header a{
    text-decoration:none;
    display:flex;
    flex-direction:row;
    
}
.row-header a:hover{
    text-decoration:none;
}

.row-header .more-but-right{
    padding:0;
    // padding-bottom:0.5em;
    align-self:center;
    padding-left: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: all 0.3s;
}
.audience-learners .row-header a:hover .more-but-right{
    margin-left: 0.2rem;
}

.row-title{
    margin-bottom:0;
    display:inline;
}
.audience-learners .row-header a .row-title{
    border-bottom:2px solid transparent;
    margin-top: 0;
}

.audience-learners .row-header a:hover .row-title{
    display:inline;
    border-bottom:2px solid $col-green;
    transition: border-bottom 0.3s;
}


.audience-kids .row-title{
    
    @include bp(m){
        max-width:50%;
    }
}
.audience-kids .row-title:hover{
    // border-bottom:2px solid $col-green;
}




.row-inner{
    margin-top:1rem;
    width:100%;
}

.rowStrip > h5 {
	font-family: $font-header;
	font-size: $font-size-h4;
	position: relative;
	margin-left: 0.65rem;
	margin-top: 0.9rem;
    margin-bottom: 0.6rem;
	text-shadow: 	-1px -1px 3px hsla(60deg, 34%, 100%, 0.4),
					-1px 1px 3px hsla(60deg, 34%, 100%, 0.4),
					1px 1px 3px hsla(60deg, 34%, 100%, 0.4),
					1px -1px 3px hsla(60deg, 34%, 100%, 0.4);
	z-index: 2;
}


.slick-arrow{
    z-index:100;
    
}

.slick-prev:before {
    content: url('../../assets/images/ui/arrow-tall-prev.png') !important;
}

.slick-next:before {
    content: url('../../assets/images/ui/arrow-tall-next.png') !important;
}
  
.slick-prev:before,
.slick-next:before {
    font-family:initial!important;
    color:black !important; 
}

.slick-list{
    overflow:visible !important;
    z-index:90;
    
}



.slick-slide{
    // margin:0 10px;
    /* opacity:0.3;
    transition: opacity 400ms ease-in; */
}

.slick-slide.slick-active{
    /* opacity:1;
    transition: opacity 400ms ease-in; */
}

.slick-prev, .slick-next{
    width:60px !important;
    height:300px !important;
}
.slick-prev{
    // left:-29px !important;
    left:-40px !important;
    @include bp(m){
        left:-22px !important;
    }
    @include bp(l){
        left:-25px !important;
    }
}

.slick-next{
    // right:-29px !important;
    right:-40px !important;
    @include bp(m){
        right:-22px !important;
    }
    @include bp(l){
        right:-25px !important;
    }
}

.row .more-but{
    align-self:flex-end;
    
}


.audience-learners .section-specialCollections .row-title{
    color:$col-green;
}

// ---------------------------------------------
// KIDS

.audience-kids .row-container{
    overflow: visible;
    padding-top:10rem;
}

.audience-kids .row-container-first{
    padding-top:0;
}

.audience-kids .section-storyTypes .row-container-first{
    padding-top:7rem;
}

.row-container-first{
    padding-top:0;
}

.audience-kids .row-header{
    justify-content: center;
    position:relative;
    overflow:visible;
    padding:0;
}

.audience-kids .row-header-image{
    position:absolute;
    top:-10rem;
    width:20rem;
    height:20rem;
    z-index:1;
    transition: opacity 1s;
    @include bp(s){
        opacity:0.1
    }
}
.audience-kids .row-header-image.unrevealed{
    opacity:0
}

.audience-kids .row-container-first .row-header-image{
    @include bp(s){
        opacity:0
    }
}


.audience-kids .row-header-image-1{
    left:0;
}

.audience-kids .row-header-image-2{
    right:0;

}

.audience-kids .row-header a{
    display:flex;
    align-self:center;
    z-index:10;
}
.audience-kids .row-title{
    z-index:2;
    text-align:center;

}
.audience-kids .section-musicAndSongs .row-title{
    color:$col-blue;
}

.audience-kids .section-places .row-title{
    color:$col-green;
}

.audience-kids .section-media .row-title{
    color:$col-pink;
}

.audience-kids .section-characters .row-title{
    color:$col-blue;
}

.audience-kids .section-storyTypes .row-title{
    color:$col-pink;
}

.audience-kids .section-specialCollections .row-title{
    color:$col-yellow-dark;
}
