// TopicLinks

@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;



#topic-links{

}

.topic-links-item{
    border:0;
    border-top: 1px solid $col-dark-vapour;
    border-color:$col-dark-vapour;
    padding:1.5rem 0.5rem;
    background-color:$col-bg;
    text-align:left;
    display:flex;
    flex-direction:column;
    width:calc(100% - 1rem);
    cursor:pointer;
    transition:all 200ms ease-in-out;
    z-index:0;
    
}
.topic-links-item:hover {
    background-color: $col-green-light;
    text-decoration: none;
}

.topic-links-item:hover *{
    // text-decoration: none;
}
.topic-links-item .item-content{
    transition:all 200ms ease-in-out;
}

.topic-links-item:hover .item-content{
    padding-left:0.1rem;
    // transform: scale(1.005);
    // transform: scale(1.002);
    // box-shadow:0 1rem 2rem $col-dark-vapour;
    // border-color:transparent;
    
    // z-index:900;
    text-decoration:none;
    // padding-left:1rem;
}

.topic-links-item .list-title{
    margin-top:0;
    margin-bottom:0.5rem;
    
}
.topic-links-item p{
    margin:0;
    margin-top:0;
    color:$col-dark-ghost;
    word-wrap:break-word;
    word-break: normal;
    font-size: $font-size-body-small;
    // font-family: $font-italic;
    // font-style: italic;
    // word-break: break-word;
    // overflow-wrap: anywhere;
}
