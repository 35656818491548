// OralItems

@use '../../assets/styles/colors' as *;
@use '../../assets/styles/breakpoints' as *;

#oral-screen .section-items{
    display:block;
    // min-height:75vh;
    min-width:100%;
    margin-top:2rem;

    .items-container{
    
        overflow:visible;
        display:flex;
        flex-direction:column;
        // width:100%;
        margin: 0;
        
        flex-wrap: wrap;
        
        .item{
            width:100%;
            
            @include bp(s){
                // padding:0 20px;
                // width:33%;
            }
        
            @include bp(m){
                // width:33%;
                // padding:0 20px;
            }
        
            @include bp(l){
                // padding:0 30px;
                // width:25%;
            }
        
            @include bp(xs){
                // padding:0 20px;
                // width:33%;
            }
            @include bp(xxs){
                // padding:0 20px;
                // width:50%;
            }
        
            @include bp(xxxs){
                // padding:0 20px;
                // width:100%;
            }
        }
    }
    
    
}


