// HeroKids

@use '../../assets/styles/colors' as *;
@use '../../assets/styles/breakpoints' as *;


#hero.audience-kids{
    width:100%;
    // height:40rem;
    background-color:#F5F4F3;
    z-index:0;
    position:relative;
    
    // @include bp(s){
    //     height:60vh;
    // }
}
#hero.audience-kids .main-column{
    display:flex;
    flex-direction:column;
}



#hero .main-column{
    width:100%;
    height:100%;
    overflow:hidden;
    display:flex;
    // align-content:flex-end;
    // justify-content:space-between;
}

#hero.audience-kids .flash-wedge{
    display:flex;
    height:0;
    // width:100%;
    // background-color: red;
    
    // transition:all 1s ease-in-out;
    @include bp(s){
        height:5rem;
    }
    @include bp(m){
        height:5rem;
    }
}

#hero.audience-kids .hero-image{
    width: 100%;
    // height: calc();
}


#hero h1{
    align-self:center;
}

#container {

}

#container canvas{
    background-color:#fff;
}