// FORM
@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/breakpoints' as *;

.form{
    
}
.control{
    display:flex;
}

// .control-select{
//     font-size:$font-size-body;
//     background-color:$col-bg-dark;
//     border:0;
//     border-radius:2rem;
//     padding:0 1rem;
//     border-right:1rem solid transparent;
// }


   