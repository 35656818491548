// Nav

@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/breakpoints' as *;

$reveal-dur:0.25s;
$reveal-dur-instant:0s;
$image-size:11rem;
$image-size-s:10rem;
$image-size-stuck:5rem;

.shortcuts-row-item{
    background-color: transparent;
    display:flex;
    flex-direction:column;
    align-self:flex-start;
    justify-content:flex-start;
    transition: all calc($reveal-dur * 0.75) cubic-bezier(0.230,  1.000, 0.320, 1.000); 
    cursor:pointer;
    border:0;
    text-align:center;
    padding:0 1.5rem;
    height:100%;
    
    
    
    @include bp(s){
        // border-top:2px solid orange;
        padding:1rem 0.5rem;
    }
    @include bp(xxs-down){
        // border-top:2px solid red;
        padding:1rem 0.5rem;
    }

    @include bp(m){
        // border-top:2px solid yellow;
        padding:1rem 0.5rem;
    }
    @include bp(l){
        // border-top:2px solid green;
        // width:10rem;
        padding:1rem 1rem;
    }

    &:hover{
        transform: scale(1.03);
        @include bp(s){
            transform: scale(1.02);
        }
        text-decoration: none;
    
    }

    
}

.shortcuts-row-item:first-child{
    padding-left:2rem;
}
.shortcuts-row-item:last-child{
    padding-right:2rem;
}

#shortcuts-container.instant .shortcuts-row-item{
    transition-duration:$reveal-dur-instant;
}


.shortcuts-row-item>a{
    text-decoration:none;
    
}

.shortcuts-row-item-image-wrapper{
    // transform:scale(1);
    transform:translateY(0);
    opacity:1;
    transition: all $reveal-dur; 
    border-radius:0.4rem;
    background-color: $col-light-vapour;
    width:$image-size;
    align-self:center;
    justify-self: center;
    transition:all $reveal-dur;
    @include bp(s){
        width:$image-size-s;
    }
}
#shortcuts-container.instant .shortcuts-row-item-image-wrapper{
    transition-duration: $reveal-dur-instant;
    
}

.shortcuts-row-item-image-container{
    
    opacity:1;

    // transform-origin:center;
    border-radius:0.4rem;
    transition: padding-bottom $reveal-dur, opacity calc($reveal-dur * 0.8), box-shadow 0.3s; 
    
    -webkit-box-shadow:0px 10px 20px 0px rgba(62,66,66,0.2);
    -moz-box-shadow: 0px 10px 20px 0px rgba(62,66,66,0.2);
    box-shadow: 0px 10px 20px 0px rgba(62,66,66,0.2);


    position:relative;
    background-color: transparent;
    display:block;

    // Ensures image is correct width and height ratio
    padding-bottom: #{calc($section-item-image-ratio * 100) + "%"};
    margin-top:0;
    width:$image-size;
    transition:all $reveal-dur;
    @include bp(s){
        width:$image-size-s;
    }
}
#shortcuts-container.instant .shortcuts-row-item-image-container{
    transition-duration: $reveal-dur-instant;
}

.shortcuts-row-item.loading .shortcuts-row-item-image-container{
    opacity:0;
    background-color: $col-bg;
}

.shortcuts-row-item.aspect-portrait .shortcuts-row-item-image-container{
    padding-bottom: #{calc($section-item-image-ratio-portrait * 100) + "%"};
}

.shortcuts-row-item.aspect-panavision .shortcuts-row-item-image-container{
    padding-bottom: #{calc($section-item-image-ratio-panavision * 100) + "%"};
}

.shortcuts-row-item:hover .shortcuts-row-item-image-container{
    -webkit-box-shadow:0px 12px 20px 0px rgba(62,66,66,0.30);
    -moz-box-shadow: 0px 12px 20px 0px rgba(62,66,66,0.30);
    box-shadow: 0px 12px 20px 0px rgba(62,66,66,0.30);
    
}

.shortcuts-row-item-image{
    width:100%;
    height: unset;
    // min-width:100%;
    // max-width:100%;
    border-radius:0.4rem;
    position:absolute;
    opacity:1;
    transition: opacity calc($reveal-dur * 0.75) cubic-bezier(0.230,  1.000, 0.320, 1.000); 
    
    display:block;
}
#shortcuts-container.instant .shortcuts-row-item-image{
    transition-duration: $reveal-dur-instant;
}


// Hide image until loaded
.shortcuts-row-item.loading-image .shortcuts-row-item-image{
    opacity: 0;
    width: 10px;
    height: 10px;
}



.audience-kids .shortcuts-row-item-image-border,
.audience-toddlers .shortcuts-row-item-image-border{
    // -webkit-box-shadow: inset 0px 0px 0px 16px $col-green;
    // -moz-box-shadow: inset 0px 0px 0px 16px $col-green;
    // box-shadow: inset 0px 0px 0px 0.6rem rgba(255,255,255,1);
    border-radius:0;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    

     @include bp(s){
        // box-shadow: inset 0px 0px 0px 0.4rem rgba(255,255,255,1);
    }
}

.shortcuts-row-item-text{
    display:flex;
    flex-direction:column;
    max-width:$image-size;
    @include bp(s){
        width:$image-size-s;
    }
}
.shortcuts-row-item-title{
    /* font-size:1.3rem; */
    margin-bottom:0;
    color:$col-light;
    margin-top:0.6em;
    line-height:1em;
    transition: all calc($reveal-dur * 0.75) cubic-bezier(0.230,  1.000, 0.320, 1.000); 
    @include bp(s){
        font-size:$font-size-body-l !important;
    }
    @include bp(m){
        font-size:$font-size-body;
    }
    @include bp(l){
        font-size:$font-size-body;
    }
}

#shortcuts-container.instant .shortcuts-row-item-title{
    transition-duration: $reveal-dur-instant;
}

.shortcuts-row-item-subtitle{
    /* font-size:1.3rem; */
    
    color:$col-light;
    margin:0;
    margin-top:0.8rem;
    font-family:$font-button;
    font-weight:normal;
    font-size:$font-size-body-small;
    transition:all $reveal-dur;
    opacity:0.8;
    transform:scale(1);

  
}
#shortcuts-container.instant .shortcuts-row-item-subtitle{
    transition-duration: $reveal-dur-instant;
}


// ------------------------------------------------------------------
// STUCK

.shortcuts.stuck{
    .shortcuts-row-item{
        align-self:center;
        justify-content:center;
        padding:0 0.6rem;
        

        .shortcuts-row-item-image-wrapper{
            transform:translateY(-400px);
            // opacity:0;
            width:$image-size-stuck;
        }
        .shortcuts-row-item-image-container{
            padding-bottom:0;
            opacity:0;
            width:$image-size-stuck;
        }

        .shortcuts-row-item-text{
            padding-top:1rem;
            padding-bottom:1rem;
            width:unset;
            @include bp(s){
                width:unset;
            }

            .shortcuts-row-item-title{
                // color:$col-header;
                font-size:$font-size-body;
                margin:0;
                padding:0;
                @include bp(s){
                    font-size:$font-size-body-small-mobile !important;
                }
            }
            .shortcuts-row-item-subtitle{
                // font-size:0;
                transform:scale(0);
                transform:translateY(-10rem);
                // line-height:0;
                height:0;
                opacity:0;
                margin-top:0;
            }
        }
       
      
    }
    .shortcuts-row-item:hover,
    .shortcuts-row-item:active{
        background-color:$col-header-dark-faint;
    }
    
  
    
}



// -------------------------------------------
// LEARNERS

#shortcuts-container.dark {

    .shortcuts.stuck{
        .shortcuts-row-item:hover{
            background-color:$col-bg-darker-translucent;
        }
        
    }
    .shortcuts-row-item-title{
        color:$col-header;
    }
    .shortcuts-row-item-subtitle{
        color:$col-header;
    }

    .shortcuts-row-item-image-wrapper{
        background-color: $col-header-vapour;
    }

    .shortcuts-row-item.loading .shortcuts-row-item-image-container{
        -webkit-box-shadow:0;
        -moz-box-shadow: 0;
        box-shadow:0;
    }
}

