@use 'assets/styles/colors' as *;
@use 'assets/styles/base' as *;
@use 'assets/styles/layout' as *;


#app {
  
  margin:0;
  display: flex;
  flex-direction: column;
  min-height:100%;
  width:100%;
  background-color: $col-bg;
}

.app-wrapper{
    width: 100%;
    height: 100%;
    // height: 100vh;
}

.app-wrapper.loading{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.app-wrapper.loading-error{
    padding:1rem;
    .message{
        padding:2rem;
        width: calc(100% - 6rem);
        background-color: $col-green-light;
        border-radius: 0.3rem;
        border:1px solid $col-green-ghost;

        .title{
            margin:0
        }

        p{
            margin-top: 1rem;
            color: $col-dark;
        }
    }
}

.app-wrapper.loading .spinner{
    align-self: center;
    justify-self: center;
    width: 3rem;
    height: 3rem;
}
