// Section

@use '../../assets/styles/colors' as *;
@use '../../assets/styles/breakpoints' as *;

.section{
    margin-top:3em;
    width:100%;
    display:block;
    // flex-direction:column;
    // align-content:center;
    // justify-content:center;
}
.section.section-musicAndSongs{
    margin-top:0;
}
.section-title{
    text-align:center;
    margin-bottom:0;
    display:flex;
    flex-direction:column;
}

.section-title-underline{
    align-self:center;
    width:5rem;
    height:0.3rem;
    background-color:#E8EBD8;
    margin-top:0.3em;
}

.section-more-but-container{
    display:flex;
    flex-direction:row;
    align-content:center;
    justify-content:center;
    margin-top:2rem;
}


// -----------------------------------------------------------------------
// LEARNERS


.audience-learners .section-specialCollections{
    background-color:$col-green-vapour;
    padding-bottom:3rem;
}

.audience-learners .section-specialCollections .section-title{
    color:$col-green;
}

// -----------------------------------------------------------------------
// TODDLERS

.audience-toddlers .section{
    margin-top:0;
    margin-bottom:4rem;
}

// -----------------------------------------------------------------------
// KIDS


// -----------
// SECTION HEADER

.audience-kids .section-places{
    margin-top:0;
}


.audience-kids .section-header{
    display:flex;
    flex-direction:column;
    align-self:center;
    // padding-bottom:2rem;
}

.audience-kids .section-musicAndSongs .section-header{
    background-color:#38729F;
    
}

.audience-kids .section-places .section-header{
    background-color:$col-green;
}

.audience-kids .section-characters .section-header{
    background-color:$col-blue;
}

.audience-kids .section-storyTypes .section-header{
    background-color:$col-pink;
}

.audience-kids .section-media .section-header{
    background-color:$col-bg-dark;
    
}

.audience-kids .section-specialCollections .section-header{
    background-color:$col-yellow;
}

// -----------
// MAIN COLUMN
.audience-kids .section-header .main-column-content{
    flex-direction:column;
    justify-content:center;
    align-content:center;
    display:flex;
}

// -----------
// IMAGE
.audience-kids .section-image-container{
    display:flex;
    position:relative;  
}
.audience-kids .section-places .section-image-container,
.audience-kids .section-characters .section-image-container,
.audience-kids .section-specialCollections .section-image-container{
    display:block;
    max-width: 50rem;
    width:70%;
    // height:30rem;
    border:1rem solid white;
    margin-top:-0.5rem;
    box-shadow:0 0.5rem 2rem 2rem $col-dark-wraith;
    align-self:center;
    background-color: $col-light-off;
    

    @include bp(m){
        width:80%
    }
    @include bp(s){
        width:100%
    }
}
.audience-kids .section-image-container{
    display: block;
}
.audience-kids .section-media .section-image-container{
    // margin-bottom:10rem;
    padding-bottom:7rem;

    @include bp(s){
        padding-bottom:10rem;
    }
    
}
.audience-kids .section-image-container .section-image{
    display: block;
    width: 100%;
}

// -----------
// TITLE
.audience-kids .section-title{
    padding-bottom:2rem;
}

.audience-kids .section-storyTypes .section-title,
.audience-kids .section-musicAndSongs .section-title, 
.audience-kids .section-media .section-title {
    position:absolute;
    bottom:0;
    // right: 2rem;
    width:100%;
}

.audience-kids .section-places .section-title span,
.audience-kids .section-characters .section-title span,
.audience-kids .section-storyTypes .section-title span,
.audience-kids .section-musicAndSongs .section-title span{
    color:$col-light;
}
.audience-kids .section-specialCollections .section-title span{
    color:$col-text-on-yellow;
}

.audience-kids .section-media .section-title span{
    color:$col-pink;
}

// -----------
// EXPANDER BUTTONS
.audience-learners .section-specialCollections .expander-buttons .but{
    background-color:$col-green-but;
    color: $col-light;
}
.audience-learners .section-specialCollections .expander-buttons .but:hover{
    // opacity: 0.8;
    background-color:$col-green-hover;
}

.audience-learners .section-specialCollections .expander-buttons .but .icon{
    color: $col-light;
}
.audience-kids .section-places .expander-buttons .but{
    background-color:$col-green-but;
}
.audience-kids .section-places .expander-buttons .but:hover{
    background-color:$col-green-hover; 
}

.audience-kids .section-characters .expander-buttons .but{
    background-color:$col-blue;
}
.audience-kids .section-characters .expander-buttons .but:hover{
    background-color:$col-blue-hover; 
}

.audience-kids .section-storyTypes .expander-buttons .but{
    background-color:$col-pink;
}
.audience-kids .section-storyTypes .expander-buttons .but:hover{
    background-color:$col-pink-hover; 
}

.audience-kids .section-media .expander-buttons .but{
    background-color:$col-pink;
}
.audience-kids .section-media .expander-buttons .but:hover{
    background-color:$col-pink-hover; 
}

.audience-kids .section-specialCollections .expander-buttons .but{
    background-color:$col-yellow;
    // color:$col-text-on-yellow;
}
.audience-kids .section-specialCollections .expander-buttons .but:hover{
    background-color:$col-yellow-hover; 
}


.audience-kids .section .expander-buttons .but span,
.audience-kids .section .expander-buttons .but svg{
    color:$col-light;
    align-self:center;
}


.audience-kids .section.section-specialCollections .expander-buttons .but span,
.audience-kids .section.section-specialCollections .expander-buttons .but svg{
    color:$col-text-on-yellow;
}


