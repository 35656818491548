// SectionHeaderToddlers

@use '../../assets/styles/colors' as *;
@use '../../assets/styles/breakpoints' as *;







.audience-toddlers .section-header{
    display:flex;
    flex-direction:column;
    // align-self:center;
    width:100%;
    height:25rem;
    // padding-bottom:2rem;
    position:relative;

}

.audience-toddlers .section-header .bg{
    width:100%;
    height:100%;
}

.audience-toddlers .section-header .images{
    position:absolute;
    width:100%;
    height:100%;
    bottom:0;
}
.audience-toddlers .section-header .image{
    position:absolute;
    bottom:0;
    width:20rem;

    @include bp(s){
        // padding:0 20px;
        width:13rem;
    }

    @include bp(m){
        width:15rem;
        // padding:0 20px;
    }

    @include bp(l){
        // padding:0 30px;
        width:20rem;
    }
}
.audience-toddlers .section-header .image-center{
    
    width:23rem;

    @include bp(s){
        // padding:0 20px;
        width:23rem;
    }

    @include bp(m){
        width:23rem;
        // padding:0 20px;
    }

    @include bp(l){
        // padding:0 30px;
        width:23rem;
    }
}
.audience-toddlers .section-header .image img{
    width:100%;
    display:block;
}
.audience-toddlers .section-header .image-left{
    left:0
}

.audience-toddlers .section-header .image-center-container{
    width:100%;
    position:absolute;
    bottom:0;
}
.audience-toddlers .section-header .image-center{
    margin:0 auto;
    position:relative;
}

.audience-toddlers .section-header .image-right{
    right:0
}


// TEXT
.audience-toddlers .section-header-text{
    display:flex;
    flex-direction:column;
    width:100%;
    position:absolute;
    bottom:3.5rem;
}
.audience-toddlers .section-title{
    text-align:center;
    // width:100%;
    margin:0;
    padding:0;
    color:$col-light;
}


.audience-toddlers .section-subtitle{
    color:$col-light;
    text-align:center;
    margin:0;
    padding:0;

}



// Backgrounds
.audience-toddlers .section-header{
    background-color:$col-green-light;
}
