// MODAL
@use '../../assets/styles/colors' as *;
@use '../../assets/styles/breakpoints' as *;



$border-radius:0.3rem;

.modal{
    position:relative;
    background-color:$col-light;
    display:flex;
    flex-direction:column;
    width:90vw;
    // max-width:1200px;
    // height:90vh;
    border-radius:$border-radius;
    margin-top:2rem;
    margin-bottom:2rem;
    margin-left:auto;
    margin-right:auto;
    box-shadow:0 5rem 5rem $col-dark-faint;
    opacity:0.99;
}

.modal.max {
    max-width: 100vw;
    width: 100vw;
    margin-top: 0;

}


.modal-header{
    background-color:$col-header;
    border-radius:$border-radius $border-radius 0 0;
    display:flex;
    position:relative;
}

.modal-header-text{
    color:$col-light;
    text-align:center;
    display:flex;
    flex-direction:column;
    align-content:center;
    width:100%;
    height:5rem;
    padding:0 7.5rem;
    justify-content:center;
}
.modal-header-text h3{
    color:$col-light;
    font-size:1.3rem;
    line-height:1em;
}
.modal-header .buts{
    position:absolute;
    top:0;
    right:0;  
    background-color:transparent;
    display: flex;
    flex-direction: row;
    margin-right: 0.5rem;
    height:100%;

}
.modal-header .but{
    align-self:center;
    background-color:transparent; 
    transition: all 0.3s;
}
.modal-header .but:hover{
    background-color:$col-highlight-wraith; 
}
.modal-header .but-icon{
    color:$col-light
}

.modal-body{
    overflow:visible;
}
.modal-header .but.close-but{
    padding:1rem;
}


.modal-content{
    display:flex;
    flex-direction: column;
    padding:0 2rem 2rem 2rem;

    @include bp(s){
        padding:0 2rem 2rem 2rem;
    }
    
}


