// ERROR SCREEN
@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/breakpoints' as *;

$header-height-l: 25rem;
$header-height-s: 25rem;
$header-height-xs: 27rem;

$header-badge_size-l: 14rem;
$header-badge_size-m: 12rem;
$header-badge_size-s: 9rem;

#oral-screen{
    position:relative;
    .content{
        margin-top:0;
    }

    .header-bg{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:$header-height-l;
        overflow:hidden;
        @include bp(s){
            height:$header-height-s;
        }

        @include bp(xs){
            height:$header-height-xs;
        }
        img{
            position:absolute;
            bottom:0;
            width:75rem;
            transition:all 0.3s;
            left:-5rem;
            @include bp(s){
                width:50rem;
            }
            @include bp(m){
                width:65rem;
            }
        }
        // bottom:0;
        // left:-15rem;
        // z-index:0;
        
       
       

        
    }
    header{
        display:flex;
        flex-direction: row;
        width:100%;
        max-width:100%;
        overflow-x:visible;
        position:relative;
        height:$header-height-l;
        @include bp(s){
            height:$header-height-s;
        }
        @include bp(xs){
            height:$header-height-xs;
        }
        // border:1px solid red;
        *{
            z-index:1
        }
        
        .text{
            position:absolute;
            display:flex;
            flex-direction:column;
            left:2rem;
            bottom:2rem;
            // Width = 100% - left margin
            width:calc(100% - 2rem);
            .title-wrapper{
                width:30rem;
                // Width = full width - left margin - badge width - badge-right - padding-right
                max-width: calc(100% - 2rem - $header-badge_size-l - 3rem - 1rem);
                @include bp(m){ 
                    // Width = full width - left margin - badge width - badge-right - padding-right
                    width:30rem;
                    max-width: calc(100% - 2rem - $header-badge_size-m - 3rem - 1rem);
                }
                @include bp(s){
                    width:20rem;
                    // Width = full width - left margin - badge width - badge-right
                    max-width: calc(100% - 2rem - $header-badge_size-s - 1rem);
                }
                @include bp(xxs-down){
                    width:20rem;
                    // Width = full width - left margin - badge width - badge-right
                    max-width: calc(100% - 2rem  - 1rem);
                }

                h1{
                    margin:0;
                    @include bp(s){
                        font-size:$font-size-h1-s;
                    }
                }
                h3{
                    margin:0;
                    font-family:$font-italic;
                    line-height:1.2em;
                }
            }

            .partners{
            
            
                display:inline-flex;
                flex-direction:row;
                text-decoration:none;
                opacity:1;
                // width:100%;
                margin-top:0.5rem;
                transition:all 0.3s;
                transform-origin: 50% 50%;
                @include bp(s){
                    // flex-direction:column;
                }
                img{
                    width:7rem;
                    height:3.5rem;
                    display:block;
                    @include bp(s){
                        width:5rem;
                        height:2.5rem;
                        // display:none;
                    }
                }
                .partners-text{
                    font-family:$font-ui;
                    font-size:$font-size-body-small;
                    color:$col-text;
                    display:flex;
                    flex-direction: column;
                    align-self:center;
                    display: inline-block;
                    margin-left:0.4rem;
                    @include bp(s){
                        // margin-left:0;
                        // align-self:flex-start;
                        font-size:$font-size-body-xs;
                    }
                    .block1{
                        color:$col-text-ghost;
                    }
                    .block2{
                        span{
                            color:$col-text-wraith;
                        }
                    }
                }
            }
            .partners:hover{
                opacity:0.9;
                transform: scale(1.025);
                
                .block2{
                    text-decoration:underline;
                }
            }
        }

        .badge{
            position:absolute;
            right:3rem;
            bottom:2rem;
            width:$header-badge_size-l;
            height:$header-badge_size-l;
            transition:all 0.3s;

            
            @include bp(s){
                right:1rem;
                bottom:5.5rem;
                width:$header-badge_size-s;
                height:$header-badge_size-s;
            }
            @include bp(xxs-down){
                right:1rem;
                bottom:6rem;
                // width:$header-badge_size-s;
                // height:$header-badge_size-s;
                display:none;
                width:0;
                height:0;
            }
            @include bp(m){
                width:$header-badge_size-m;
                height:$header-badge_size-m;
            }
        }
    }

    .grid-header{
        display:flex;
        flex-direction:row;
        width:100%;
        justify-content:space-between;
        margin-top:2rem;

        @include bp(s){
            flex-direction:column;
            margin-top:1rem;
        }
        
        .controls{
            display:flex;
            flex-direction:row;
            @include bp(s){
                // justify-content:flex-end;
                margin-top:1rem;
                flex-direction:column;
            }
           
            .control-select{
                color:$col-header;
                margin-left: 1rem;
                @include bp(s){
                    margin-left:0;
                    margin-top:1rem;
                }
                &:first-child{
                    margin-left: 0;

                    @include bp(s){
                        margin-top:0;
                    }
                }

            }
			.control-select.lang {
				// margin-left: 1rem;
			}

            .clear-but{
                margin-left:1rem;
                color:$col-header;

                @include bp(s){
                    margin-left:0;
                    margin-top:1rem;
                    align-self:flex-start;
                }
                .label{
                    color:$col-header;
                }
            }
            
        }

    }
    .loading{
        display:flex;
        flex-direction:row;
        align-content:center;
        justify-content:center;
        width:100%;
        .spinner{
            margin-top:3rem;
            align-self:center;
            width: 3rem;
            height: 3rem;
            // margin-right: 1rem;
        }
    }
    .error{
        
    }
    
    
}