// Nav

@use '../../assets/styles/colors' as *; 
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/breakpoints' as *;


#lang {
    background-color: $col-header;
    width:100%;
    padding:1rem 0 1.5rem 0;
    color:$col-light;
    position:relative;
}

.audience-learners #lang{
    // background-color:$col-bg-dark;
    // background-color:$col-bg;
    background: rgb(25,14,45);
    background: linear-gradient(0deg, rgba(25,14,45,0) 0%, rgba(236,236,230,1) 100%); 

}



.audience-toddlers #lang{
    padding-top:6rem;
}

.lang--inner{
    display:flex;
    flex-direction:column;
}



.lang--title{
    display:flex;
    flex-direction:row;
    align-self:center;
    align-content:center;
    justify-content:center;

}

.lang--title--icon{
    width:2rem;
    height:2rem;
    background-size:2rem 2rem;
    margin-right:0.5rem;
}
.lang--title--icon-ga{background-image:url(../../assets/images/ui/menu-icon-lang-light-ga.png);}
.lang--title--icon-en{background-image:url(../../assets/images/ui/menu-icon-lang-light-en.png);}
.audience-learners .lang--title--icon-ga{background-image:url(../../assets/images/ui/menu-icon-lang-dark-ga.png);}
.audience-learners .lang--title--icon-en{background-image:url(../../assets/images/ui/menu-icon-lang-dark-en.png);}
.lang--title h3{
    color:$col-light;
    align-self:center;
    margin:0;
    font-weight:bold;
}
.audience-learners .lang--title h3{
    color:$col-header;
}

.lang--buttons{
    display:flex;
    flex-direction:row;
    align-self:center;
    margin-top:1rem;
}

.lang--buttons .button{
    border-radius:0 0.5rem 0.5rem 0;
    font-size:$font-size-body-small;
    font-family: $font-button;
    font-weight:bold;
    border:0;
    padding:1.2rem 0.8rem;
    margin-left:0.15rem;
    // min-width:6rem;
    // background-color: $col-light-wraith;
    background-color: $col-highlight-wraith;
    color:$col-light;
    cursor: pointer;
    transition: all 0.3s;
    opacity:1;
    text-align: center;
    padding-left:1rem;
    padding-right:1rem;

    @include bp(xxs-down){
        font-size:$font-size-body-small-mobile;
        padding:1rem 0.7rem;
    }
    @include bp(s){
        font-size:$font-size-body-small-mobile;
        padding:0.8rem 0.7rem;
    }

}
.lang--buttons .button:first-child{
    margin-left:0;
    border-radius:0.5rem 0 0 0.5rem;
}



.lang--buttons .button:hover{
    text-decoration: none;

}


.lang--buttons .button:hover, .lang--buttons .button:focus{
    background-color: $col-highlight-light;
    color:$col-light;
}


.lang--buttons .button:active{
    background-color: $col-highlight;
}


.lang--buttons .button.active{
    background-color: $col-highlight;
}

.lang--buttons .button.active:hover{
    background-color: $col-highlight;
}


// -------------------
// LEARNERS

.audience-learners .lang--buttons .button{
    color:$col-header;
}


.audience-learners .lang--buttons .button.active{
    color:$col-light;
}

.audience-learners .lang--buttons .button:hover, .audience-learners .lang--buttons .button:focus{
    color:$col-header;
    background-color: $col-highlight-ghost;
}


.audience-learners .lang--buttons .button:active{
    color:$col-light;
}

.audience-learners .lang--buttons .button.active{
    background-color: $col-highlight;
}

.audience-learners .lang--buttons .button.active:hover, .audience-learners .lang--buttons .button.active:focus{
    background-color: $col-highlight;
    color:$col-light;
}

