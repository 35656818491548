// TopicLibrary

@use '../../assets/styles/colors' as *;



#topic-library{

}

.topic-library-list-item{
    border:0;
    border-top: 1px solid $col-dark-vapour;
    padding:0;
    border-color:$col-dark-vapour;
    padding:1.5rem 0.5rem;
    // padding-left:1rem;
    background-color:$col-bg;
    text-align:left;
    display:flex;
    flex-direction:column;
    width:calc(100% - 1rem);
    cursor:pointer;
    transition:all 200ms ease-in-out;
    
}
.topic-library-list-item:hover{
    // transform: scale(1.002);
    // box-shadow:0 1rem 2rem $col-dark-vapour;
    // border-color:transparent;
    background-color: $col-green-light;
    z-index:900;
    text-decoration: none;
}

.topic-library-list-item .item-content{
    transition:all 200ms ease-in-out;
}
.topic-library-list-item:hover .item-content{
    padding-left:0.1rem;
    // transform: scale(1.005);
}

.topic-library-list-item h3{
    margin-top:0;
    margin-bottom:0;
}

.topic-library-list-item .publication{
    margin-top:0.5rem;
    margin-bottom:0;
}

.topic-library-list-item .author{
    margin:0;
    margin-top:0.7rem;
}
.topic-library-list-item .publisher{
    margin:0;
    margin-top:0.7rem;
    
}
.topic-library-item h1{
    margin-top:0;
    margin-bottom:0;
}

.topic-library-item .publication{
    margin-top:0.5rem;
    margin-bottom:0;
}

.topic-library-item .author{
    margin:0;
    margin-top:0.7rem;
}
.topic-library-item .publisher{
    margin:0;
    margin-top:0.5rem;
    
}

.topic-library-item .text{
    display:flex;
    flex-direction:column;
}
.topic-library-text-underline{
    align-self:center;
    width:5rem;
    height:0.3rem;
    background-color:#E8EBD8;
    margin-top:2em;
}
