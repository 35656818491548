// TopicOverview

// @use '../../assets/styles/colors' as *;
@use '../../assets/styles/breakpoints' as *;



#topic-overview{

}

.topic-header{

}


.topic-body{
    
}

#topic-overview .main-column-content{
    display: flex;
    flex-direction: row;
    
    @include bp(s){ 
        flex-direction: column;
    }
}

#topic-overview .main-column-content-main{
    width:72%;
    padding-right: 3%;

    @include bp(s){ 
        width:100%;
        padding-right: 0;
    }
}

#topic-overview .main-column-content-main.no-side-col{
    width:100%;
}

#topic-overview .main-column-content-side{
    width:25%;

    @include bp(s){ 
        width:100%;
    }
}