// MEDIA ITEM

@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/breakpoints' as *;



.media-item{
    width:100%;
    
    border-radius:5px;
    box-shadow:0 1rem 1rem $col-dark-vapour;
    cursor:pointer;
    border:0;
    padding:0;
    background-color:$col-light;
    
    transition: all 200ms ease-out;
    display:flex;
    flex-direction: column;
    margin-bottom: 1rem;
    text-align:left;
    position:relative;
    outline:0;
    @include bp(m){
        width:100%;
    }
    @include bp(s){
        width:48%;
        margin-right:2%;
        margin-bottom: 2rem;
    }

  
}

.audience-kids .media-item,
.audience-toddlers .media-item{
    width:100%;
    
   
    @include bp(s){
        width:48%;
    }
    @include bp(xxs-down){
        width:100%;
        
    }

  
}


.audience-kids .media-item.active,
.audience-toddlers .media-item.active{
    cursor:default;
}

.audience-kids .media-item.active .media-item-content,
.audience-toddlers .media-item.active .media-item-content{
    opacity:1;
}

.audience-kids .media-item.active .border,
.audience-toddlers .media-item.active .border{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 4px solid $col-highlight;
    border-radius: 5px;
    opacity:0.8
  }


.media-item:hover{
    box-shadow:0 1rem 1rem $col-dark-wraith;
    transform: scale(1.01);
    text-decoration: none;
}

.audience-kids .media-item.active:hover,
.audience-toddlers .media-item.active:hover{
    box-shadow:0 1rem 1rem $col-dark-vapour;
    transform: scale(1);
}

.media-item-content{
    display:flex;
    flex-direction:column;
    width: 100%;
}
.media-item-image-container{
    width: 100%;
    display: flex;
    position:relative;
    background-color:$col-bg;
    border-radius: 5px 5px 0 0;
    padding-bottom: #{calc($media-grid-image-ratio * 100) + "%"};
}



.media-item-image{

    border-radius: 5px 5px 0 0;

    // Fill to match the containing div
    width:100%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: 0px;
    left: 0px;
    transition: opacity 0.3s;
}

.media-item.loading .media-item-image{
    opacity: 0;
}

.media-item-text{
    display:flex;
    flex-direction:column;
    padding:0.5rem;
}

.media-item h3.grid-item{
    
    font-size:1.2rem;
    margin:0;
}

.media-item p{
    margin:0;
    color:$col-dark-faint;
    margin-top:0.3rem;
    font-size: $font-size-body-tinycaps;
    text-transform:uppercase;
    font-family: $font-button;
    letter-spacing:0.04em;
    // font-style: italic;
}

.media-item-icon{
    position:absolute;
    border-radius:5px 0 5px 0;
    width:3.2rem;
    height:3.2rem;
    background-size: cover;
    z-index:10;

}
.media-item-icon--video{background-image:url('../../assets/images/ui/media-video.png');}
.media-item-icon--audio{background-image:url('../../assets/images/ui/media-audio.png');}
.media-item-icon--script{background-image:url('../../assets/images/ui/media-story.png');}
.media-item-icon--comic{background-image:url('../../assets/images/ui/media-comic.png');}
.media-item-icon--game{background-image:url('../../assets/images/ui/media-game.png');}
.media-item-icon--archive-text{background-image:url('../../assets/images/ui/media-archive-text.png');}
.media-item-icon--archive-sound{background-image:url('../../assets/images/ui/media-archive-sound.png');}
