// GROUP SCREEN
@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/breakpoints' as *;

#group-screen {
    min-height:100%;
    display:flex;
    flex-direction:column;
}

.banner{
    width:100%;
    background-color:$col-green-light;
    height:20rem;
    
}




.banner .main-column{
    height:100%;
}

.banner .main-column-content{
    height:100%;
    display:flex;
    flex-direction:row;
    
    position:relative;
}

.banner--inner{
    display:flex;
    flex-direction: row;
    justify-content:space-between;
    height:100%;
}

.banner--text{
    display:flex;
    flex-direction:column;
    align-self:flex-end;
    max-width:60%;
    @include bp(m){
        max-width:100%
    }
    @include bp(s){
        max-width:100%
    }
    
}

.banner--text h1{
    margin:0;
    margin-bottom:1.5rem;
    word-wrap:anywhere;

    @include bp(s){
        line-height:1em;
    }
}

.banner--text h3{
    margin:0;
    font-family:$font-italic;
}

.banner--imagewrapper{
    
    position:absolute;
    right:0;
    bottom:0;
    display:flex;
    align-self:flex-end;
    width:30rem;
    height:fit-content;
    transition: opacity 400ms ease-in;
    display: flex;
    flex-direction: column;
    align-content: flex-end;
    opacity:0;

    @include bp(s){
        width:25rem;
        opacity:0.3;
    }

    @include bp(m){
        width:25rem;
        opacity:0.3;
    }
}

.banner.image-loaded .banner--imagewrapper{
    opacity: 1;

    @include bp(s){
        width:25rem;
        opacity:0.3;
    }

    @include bp(m){
        width:25rem;
        opacity:0.3;
    }
}



.banner--image{
    width:100%;

}

.banner--image.preloader{
    width:1px;
    height:1px;
    opacity:0;
    position:absolute;
}

.group-items{
    margin:3rem 0;
    display:flex;
    flex-direction:row;
    width:100%;
    flex-wrap: wrap;
}

.group-item{
    
    width:25%;
    margin-bottom:4rem;
    

    @include bp(m){
        width:33%;
    }

    @include bp(s){
        width:50%;
    }
}