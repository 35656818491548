// ERROR SCREEN
@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/breakpoints' as *;


// .control{
//     display:flex;
// }

.control-button{
    font-size:$font-size-body;
    background-color:$col-bg-dark;
    border:0;
    border-radius:2rem;
    padding:1rem 1.2rem;
    height:4rem;
    cursor:pointer;
    min-width:7rem;
    justify-content:center;
    
    .label{
        align-self:center;
    }
    .icon{
        align-self:center;
    }
    .text-icon{
        padding-left:0.5rem;
    }
}
.control-button.disabled{
    opacity:0.5;
    cursor:default;
}
.control-button:hover{
    background-color:$col-bg-darker;
}
.control-button.disabled:hover{
    background-color:$col-bg-dark;
}


   