// SectionItem

@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/breakpoints' as *;

$shadow-opacity: 0.05;
$shadow-opacity-hover: 0.2;

.row-item{
    background-color: transparent;
    /* background-color: #aaa; */
    /* border-radius:5px; */
    // max-height:25rem;
    // min-width:100%;
    display:flex;
    flex-direction:column;
    justify-content:center;
    transform: scale(1);
    transition: all 600ms cubic-bezier(0.230,  1.000, 0.320, 1.000); 
    border:0;
    text-align:left;
    padding:0 10px;
    @include bp(s){
        padding:0 5px;
    }
    @include bp(m){
        padding:0 5px;
    }
    @include bp(l){
        padding:0 8px;
    }
    cursor:pointer;
}



.row-item:hover{
    transform: scale(1.03);
    @include bp(s){
        transform: scale(1.02);
    }
    text-decoration: none;

}

// While loading topic data
.row-item.disabled{
    opacity:0.3,
}




.row-item>a{
    text-decoration:none;
}



.row-item-image-container{
    border-radius:0.2rem;
    transition: all 600ms cubic-bezier(0.230,  1.000, 0.320, 1.000); 
    -webkit-box-shadow:0px 10px 20px 0px rgba(62,66,66,$shadow-opacity);
    -moz-box-shadow: 0px 10px 20px 0px rgba(62,66,66,$shadow-opacity);
    box-shadow: 0px 10px 20px 0px rgba(62,66,66,$shadow-opacity);
    position:relative;
    background-color: $col-bg;
    display:block;

    // Ensures
    padding-bottom: #{calc($section-item-image-ratio * 100) + "%"};
}

.row-item.aspect-portrait .row-item-image-container{
    padding-bottom: #{calc($section-item-image-ratio-portrait * 100) + "%"};
}

.row-item.aspect-panavision .row-item-image-container{
    padding-bottom: #{calc($section-item-image-ratio-panavision * 100) + "%"};
}

.row-item:hover .row-item-image-container{
    -webkit-box-shadow:0px 12px 20px 0px rgba(62,66,66,$shadow-opacity-hover);
    -moz-box-shadow: 0px 12px 20px 0px rgba(62,66,66,$shadow-opacity-hover);
    box-shadow: 0px 12px 20px 0px rgba(62,66,66,$shadow-opacity-hover);
    
}

.row-item-image{
    width:100%;
    height: unset;
    min-width:100%;
    max-width:100%;
    border-radius:0.2rem;
    position:absolute;
    opacity:1;
    transition: opacity 0.3s;
    display:block;
}



// Hide image until loaded
.row-item.loading-image .row-item-image{
    opacity: 0;
    width: 10px;
    height: 10px;
}


// When the item has 'row' layout, i.e. elements laid out in a row (e.g. on the Oral Traditions page)
.layout-row.row-item {

    .row-item-text{
        .row-item-description{
            padding-bottom:0;
            margin-bottom:0;
        }

        .row-item-filter-tags{
            margin-top:0.5rem;
        }
    }

    @include bp(xxxs){
        margin-top:2rem;
    }

    @include bp(xxs){
        margin-top:2rem;
        // border:2px solid red;

    }
    @include bp(xxs-up){

        // Align as a row
        flex-direction:row;
        justify-content:flex-start;
        
        .row-item-text{
            margin-left:1.5rem;

            h3{
                margin-top:0;
            }
        }
    }

    @include bp(xs-up){
        margin-top:2rem;
        .row-item-image-container{
            width:15rem;
            min-width:15rem;
            max-width:15rem;
        }
        
    }

    @include bp(xxs){
        .row-item-image-container{

            // Smaller image at this breakpoint
            width:7rem;
            min-width:7rem;
            max-width:7rem;
        }
    }
    @include bp(xs){
        .row-item-image-container{

            // Smaller image at this breakpoint
            width:10rem;
            min-width:10rem;
            max-width:10rem;
        }
    }
   
}

.layout-row.row-item:hover{
    transform:scale(1.005);
}



.row-item-icon{
    position:absolute;
    border-radius:0 0 5px 0;
    width:2.7rem;
    height:2.7rem;
    background-size: cover;
    // z-index:10;
    top:0.5rem;
    left:0.5rem;

    @include bp(s){
        top:0.3rem;
        left:0.3rem;
        width:2.2rem;
        height:2.2rem;
    }
    @include bp(m){
        top:0.4rem;
        left:0.4rem;
        width:2.5rem;
        height:2.5rem;
    }
    @include bp(l){
        top:0.5rem;
        left:0.5rem;
        width:2.7rem;
        height:2.7rem;
    }


}
.row-item-icon--video{background-image:url('../../assets/images/ui/media-video.png');}
.row-item-icon--audio{background-image:url('../../assets/images/ui/media-audio.png');}
.row-item-icon--script{background-image:url('../../assets/images/ui/media-story.png');}
.row-item-icon--comic{background-image:url('../../assets/images/ui/media-comic.png');}
.row-item-icon--game{background-image:url('../../assets/images/ui/media-game.png');}

.row-item-filter-tags{
    font-size:$font-size-body-tinycaps;
    text-transform:uppercase;
    color:$col-dark;
    font-family:$font-button;
    margin-top:0;
}

.audience-kids .row-item-image-border,
.audience-toddlers .row-item-image-border{
    // -webkit-box-shadow: inset 0px 0px 0px 16px $col-green;
    // -moz-box-shadow: inset 0px 0px 0px 16px $col-green;
    box-shadow: inset 0px 0px 0px 0.6rem rgba(255,255,255,1);
    border-radius:0;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;

    @include bp(s){
        box-shadow: inset 0px 0px 0px 0.3rem rgba(255,255,255,1);
    }
    @include bp(m){
        box-shadow: inset 0px 0px 0px 0.4rem rgba(255,255,255,1);
    }
    @include bp(l){
        box-shadow: inset 0px 0px 0px 0.5rem rgba(255,255,255,1);
    }

}

.row-item-text{
    display:flex;
    flex-direction:column;
}
h3.row-item-title{
    /* font-size:1.3rem; */
    margin-bottom:0;
    color:#141414;
    margin-top:0.6em;
    line-height:1.1em;

    

    @include bp(s){
        font-size:$font-size-body-l;
    }
    @include bp(m){
        font-size:$font-size-body-l;
    }
}

.row-item-subtitle{
    /* font-size:1.3rem; */
    
    color:$col-dark-faint;
    margin:0;
    margin-top:0.4rem;
    font-weight:normal;
    font-size:$font-size-body-l;
    line-height:1.2em;
    font-family:$font-italic;
    font-style: italic;

    @include bp(s){
        font-size:$font-size-body-l-mobile;
    }
}

.difficulty-badge{
    border-radius:0.15rem;
    font-family: $font-button;
    text-transform:uppercase;
    font-size:$font-size-body-tinycaps;
    color:$col-dark;
    margin-top:0.5rem;
    align-self: flex-start;
    padding:0.3rem 0.6rem;
    color:$col-dark;
    letter-spacing:0.075em;
}
.difficulty-badge-level1{
    background-color:$col-difficulty-faint-1;
    // border:1px solid $col-difficulty-1;
}
.difficulty-badge-level2{
    background-color:$col-difficulty-faint-2;
    // border:1px solid $col-difficulty-2;
}
.difficulty-badge-level3{
    background-color:$col-difficulty-faint-3;
    // border:1px solid $col-difficulty-3;
}
.difficulty-badge-level4{
    background-color:$col-difficulty-faint-4;
    // border:1px solid $col-difficulty-4;
}
.difficulty-badge-level5{
    background-color:$col-difficulty-faint-5;
    // border:1px solid $col-difficulty-5;
}
.difficulty-badge-level6{
    background-color:$col-difficulty-faint-6;
    // border:1px solid $col-difficulty-6;
}
.row-item-description{
    font-size:calc($font-size-body * 0.9);
    line-height:1.4em;
    margin-top:0.5em;
    // opacity:0.6;
    color:$col-dark;
    transition:all 300ms ease-out;

    // @include bp(s){
    //     font-size:$font-size-body-mobile;
    // }
}


.row-item:hover .row-item-description{
    opacity:1;
}






