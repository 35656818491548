@use '../../assets/styles/colors' as *;


.modal-media-credits{
    margin-top:1rem;
    display:flex;
    flex-direction:row;
}
.modal-media-credits p{
    margin:0;
    padding:0;
}

.credit-row{
    margin-top:0.3rem;
    border:0;
}


.credit-row td{
    vertical-align: top;
}

.credit-row .cell-content{
    
}

.credit-column{
    // width:10rem;
}
.credit-column.job{
    color: $col-dark-faint;
    // min-width:10rem;
    max-width:15rem;
    padding-right:1rem;
    border:0;
    opacity: 1;
}

.credit-column.name p{
    color: $col-dark;
}

