@use '../../assets/styles/colors' as *;

:root{
    --preloader-background: #fafaf7;
    --logo-display: none;
}



.flash-block{
    position:relative;
    width:100%;
    // background-color:$col-green-light;
}
.flash-container{
    display:flex;
    width:100%;
    border:0;
    padding:0;
    justify-content:center;
    align-content:center;
    
}
.flash-loading{
    position:absolute;
    z-index:0;
    width:100%;
    height:100%;
    top:0;
    display:flex;
    justify-content:center;
    align-content:center;
    background-color:$col-green-light;
}


.flash-loading .spinner-icon{ 
    align-self:center;
}
