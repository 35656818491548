// TopicResearch




#topic-research{

}

.research-text{
    display:flex;
    flex-direction:column;
    margin-top: 2rem;
}

.research-underline{
    align-self:center;
    width:5rem;
    height:0.3rem;
    background-color:#E8EBD8;
    margin-top:2em;
}
