// Nav

@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;




.snack{
    background-color:$col-dark-deep;
    color:#fff;
    padding:1rem;
    border-radius:0.2rem;
    box-shadow:0 0.5rem 2rem $col-dark-faint;
    font-weight:bold;
    display:flex;
    flex-direction: row;
}
.snack p{
    margin:0;
    font-family:$font-snack;
}
.snack--success{
    background-color:$col-highlight;
}

.snack--error{
    background-color:$col-error;
}

.snack--warning{
    background-color:$col-warning;
}

.snack-but{
    border:0;
    border-radius:2rem;
    background-color: $col-light;
    color:$col-dark;
    margin-left:2rem;
    padding: 0.5rem 0.5rem;
    cursor:pointer;
    font-weight:bold;
    min-width: 3rem;
    height: fit-content;
}
.snack-but:hover{
    background-color: $col-green;
    color:$col-light;
}