// MODAL SEARCH RESULTS ITEM

@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;
@use '../../assets/styles/breakpoints' as *;
@use '../../assets/styles/modal' as *;




.search-results-item{
    // margin-bottom:2rem;
    display:flex;
    flex-direction: row;
    
    transition:all 0.25s;
    border:0;
    background-color: transparent;
    width:100%;
    text-align:left;
    opacity:0;
}

.search-results-item.revealed{
    opacity:1;
}

.search-results-item:hover{
    background-color:$col-highlight-vapour;
    cursor: pointer;
    text-decoration: none;
}

.search-results-item:hover .search-results-item-inner{
    // padding-left:0.3rem;
    transform: scale(1.005)
    
}

.search-results-item-inner{
    transition: all 0.25s;
    // padding-left:0;
    display:flex;
    flex-direction:row;
    padding:2rem;
}

.search-results-item .media-item-icon {
	border-radius:0.2rem 0 5px 0;
}





.search-results-item .thumb-block{
    border:0;
    padding:0;
    margin-right:1rem;
    width:12rem;
    height:fit-content;
    min-width: 12rem;
    border-radius:0.2rem;
    transition: all 600ms cubic-bezier(0.230,  1.000, 0.320, 1.000); 
    -webkit-box-shadow:0px 10px 20px 0px rgba(62,66,66,0.2);
    -moz-box-shadow: 0px 10px 20px 0px rgba(62,66,66,0.2);
    box-shadow: 0px 10px 20px 0px rgba(62,66,66,0.2);
    cursor:pointer;
    // display:flex;
    display: block;
    // transform: scale(1);
    opacity:0;
}

.search-results-item .thumb-block.loaded{
    opacity:1;
}

.search-results-item:hover .thumb-block{
    
    -webkit-box-shadow:0px 12px 20px 0px rgba(62,66,66,0.35);
    -moz-box-shadow: 0px 12px 20px 0px rgba(62,66,66,0.35);
    box-shadow: 0px 12px 20px 0px rgba(62,66,66,0.35);
    // transform: scale(1.02);
    
}

.search-results-item .thumb-block img{
    display: block;
    width:100%;
    border-radius:0.2rem;
    
}
.search-results-item .text-block{
    display:flex;
    flex-direction: column;
}

.search-results-item .surtitle{
    margin:0;
    margin-bottom:0.3rem;
    color:$col-dark-faint;
    
}

.search-results-item .surtitle .separator{
    padding-left:0.7em;
    padding-right:0.7em;
}
.search-results-item .title-but{
    background-color:transparent;
    border:0;
    padding:0;
    margin:0;
    transition: background-color 0.2s;
    cursor:pointer;
}
.search-results-item .title-but:hover{
    // background-color:$col-highlight-vapour;
    
}
.search-results-item .title{
    margin:0;
}

.search-results-item .subtitle{
    margin:0;
    margin-top:0.2rem;
    color:$col-dark-faint;
    font-size:$font-size-body-l;
    font-family:$font-italic;
}
.search-results-item .subtitle .separator{
    padding-left:0.7em;
    padding-right:0.7em;
}

.search-results-item .text{
    margin:0;
    margin-top:0.3rem;
}


.search-results-item p{
    margin:0;
}

