// SectionItems

@use '../../assets/styles/colors' as *;
@use '../../assets/styles/breakpoints' as *;


.audience-toddlers .items-container{
    
    overflow:visible;
    display:flex;
    flex-direction:row;
    // width:100%;
    margin: 0 20px;
    margin-top:-1rem;
    flex-wrap: wrap;
}

.item{
    width:20%;
    
    @include bp(s){
        // padding:0 20px;
        width:33%;
    }

    @include bp(m){
        width:33%;
        // padding:0 20px;
    }

    @include bp(l){
        // padding:0 30px;
        width:25%;
    }

    @include bp(xs){
        // padding:0 20px;
        width:33%;
    }
    @include bp(xxs){
        // padding:0 20px;
        width:50%;
    }

    @include bp(xxxs){
        // padding:0 20px;
        width:100%;
    }
}

.audience-toddlers .items-container .item{
    margin-bottom: 4rem;
}


  
.audience-toddlers .section-films .more-but{
    background-color:$col-green;
}

.audience-toddlers .section-music .more-but{
    background-color:$col-blue;
}

.audience-toddlers .section-comics .more-but{
    background-color:$col-pink;
}
