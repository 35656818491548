
@use '../../assets/styles/colors' as *;
@use '../../assets/styles/vars' as *;




.debug{
    position: fixed;
    top:0rem;
    left:0rem;
    background-color: #ffffffee;
    color: $col-dark;
    z-index:1000;
    box-shadow: 0 1rem 2rem #00000044;
    // border:2px solid $col-dark-faint;
    // display:none;
    transition:opacity 0.3s;
    min-width: 25vw;
}
#debug-drag-but{
    width: 100%;
    height: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    cursor:grab;
}
#debug-drag-but .drag-line{
    width: 50%;
    height: 1px;
    align-self: center;
    background-color: $col-dark-vapour;
}
.debug-buts{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding:0 0.5rem;
}

.debug-buts .but{
    border: 1px solid $col-dark-ghost;
    // width: 2rem;
    // height: 2rem;
    padding: 0.2rem 0.5rem;
}
.debug ul{
    margin: 0;
    padding: 0.5rem;
}

.debug li{
    list-style: none;
    margin: 0;
    font-family: $font-button;
    font-size: $font-size-body-xs;
    max-width: 90vw;
    // padding: 0.2rem;
}


